import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom-v5-compat';
import {
  ContainerModel,
  DaemonSetModel,
  DeploymentModel,
  JobModel,
  NamespaceModel,
  NodeModel,
  PodModel,
  StatefulSetModel,
} from '../../models';
import { AlertmanagerConfig } from './alertmanager/alertmanager-config';
import AlertmanagerYAML from './alertmanager/alertmanager-yaml-editor';

export const alertMessageResources: {
  [labelName: string]: { kind: string; namespaced?: boolean };
} = {
  container: ContainerModel,
  daemonset: DaemonSetModel,
  deployment: DeploymentModel,
  job: JobModel,
  namespace: NamespaceModel,
  node: NodeModel,
  pod: PodModel,
  statefulset: StatefulSetModel,
};

export const MonitoringUI = () => (
  <Routes>
    {/* This redirect also handles the `/monitoring/#/alerts?...` link URLs generated by
  Alertmanager (because the `#` is considered the end of the URL) */}
    <Route path="" element={<Navigate to="/monitoring/alerts" replace />} />
    <Route path="alertmanagerconfig" element={<AlertmanagerConfig />} />
    <Route path="alertmanageryaml" element={<AlertmanagerYAML />} />
  </Routes>
);
