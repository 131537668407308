import { applyCodeRefSymbol } from '@console/dynamic-plugin-sdk/src/coderefs/coderef-resolver';
const activePlugins = [];
activePlugins.push({
name: '@console/app',
extensions: [
...require('@console/app/src/plugin.tsx').default,
...[
{
"type": "console.perspective",
"properties": {
"id": "admin",
"default": true,
"name": "%console-app~Administrator%",
"icon": applyCodeRefSymbol(() => import('@console/app/src/utils/perspective.tsx' /* webpackChunkName: 'app/code-refs/perspective' */).then((m) => m.icon)),
"landingPageURL": applyCodeRefSymbol(() => import('@console/app/src/utils/perspective.tsx' /* webpackChunkName: 'app/code-refs/perspective' */).then((m) => m.getLandingPageURL)),
"importRedirectURL": applyCodeRefSymbol(() => import('@console/app/src/utils/perspective.tsx' /* webpackChunkName: 'app/code-refs/perspective' */).then((m) => m.getImportRedirectURL))
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsoleSample"
},
"flag": "CONSOLESAMPLE"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsoleQuickStart"
},
"flag": "CONSOLE_QUICKSTART"
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "OPENSHIFT_BUILD",
"model": {
"group": "build.openshift.io",
"version": "v1",
"kind": "Build"
}
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "OPENSHIFT_BUILDCONFIG",
"model": {
"group": "build.openshift.io",
"version": "v1",
"kind": "BuildConfig"
}
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "apps.openshift.io",
"version": "v1",
"kind": "DeploymentConfig"
},
"flag": "OPENSHIFT_DEPLOYMENTCONFIG"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "image.openshift.io",
"version": "v1",
"kind": "ImageStream"
},
"flag": "OPENSHIFT_IMAGESTREAM"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "template.openshift.io",
"version": "v1",
"kind": "Template"
},
"flag": "OPENSHIFT_TEMPLATE"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "project.openshift.io",
"version": "v1",
"kind": "Project"
},
"flag": "OPENSHIFT_PROJECT"
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "OPENSHIFT_USER_API",
"model": {
"group": "user.openshift.io",
"version": "v1",
"kind": "User"
}
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "OPENSHIFT_GROUP_API",
"model": {
"group": "user.openshift.io",
"version": "v1",
"kind": "Group"
}
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "OPENSHIFT_OAUTH_API",
"model": {
"group": "config.openshift.io",
"version": "v1",
"kind": "OAuth"
}
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "DEVWORKSPACE",
"model": {
"group": "workspace.devfile.io",
"version": "v1alpha1",
"kind": "DevWorkspace"
}
}
},
{
"type": "console.flag/model",
"properties": {
"flag": "v1alpha2DEVWORKSPACE",
"model": {
"group": "workspace.devfile.io",
"version": "v1alpha2",
"kind": "DevWorkspace"
}
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "config.openshift.io",
"version": "v1",
"kind": "OAuth"
},
"component": applyCodeRefSymbol(() => import('@console/app/src/components/oauth-config/OAuthConfigDetailsPage.tsx' /* webpackChunkName: 'app/code-refs/oauthConfigDetailsPage' */).then((m) => m.default))
},
"flags": {
"required": [
"OPENSHIFT_OAUTH_API"
]
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/app/src/components/tour/tour-context.ts' /* webpackChunkName: 'app/code-refs/tourContext' */).then((m) => m.TourContextProvider)),
"useValueHook": applyCodeRefSymbol(() => import('@console/app/src/components/tour/tour-context.ts' /* webpackChunkName: 'app/code-refs/tourContext' */).then((m) => m.useTourValuesForContext))
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/app/src/components/quick-starts/utils/quick-start-context.tsx' /* webpackChunkName: 'app/code-refs/quickStartContext' */).then((m) => m.QuickStartContextProvider)),
"useValueHook": applyCodeRefSymbol(() => import('@console/app/src/components/quick-starts/utils/quick-start-context.tsx' /* webpackChunkName: 'app/code-refs/quickStartContext' */).then((m) => m.useValuesForQuickStartContext))
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/app/src/components/file-upload/file-upload-context.ts' /* webpackChunkName: 'app/code-refs/fileUploadContext' */).then((m) => m.FileUploadContextProvider)),
"useValueHook": applyCodeRefSymbol(() => import('@console/app/src/components/file-upload/file-upload-context.ts' /* webpackChunkName: 'app/code-refs/fileUploadContext' */).then((m) => m.useValuesFileUploadContext))
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"id": "volumesnapshotcontents",
"section": "storage",
"name": "%console-app~VolumeSnapshotContents%",
"insertAfter": "volumesnapshotclasses",
"model": {
"group": "snapshot.storage.k8s.io",
"kind": "VolumeSnapshotContent",
"version": "v1"
}
},
"flags": {
"required": [
"CAN_LIST_VSC"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "Deployment"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useCreateServiceBindingProvider))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps.openshift.io",
"version": "v1",
"kind": "DeploymentConfig"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useCreateServiceBindingProvider))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useCreateServiceBindingProvider))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "Deployment"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useDeploymentActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps.openshift.io",
"version": "v1",
"kind": "DeploymentConfig"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useDeploymentConfigActionsProvider))
},
"flags": {
"required": [
"OPENSHIFT_DEPLOYMENTCONFIG"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "StatefulSet"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useStatefulSetActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "DaemonSet"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useDaemonSetActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "batch",
"version": "v1",
"kind": "Job"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useJobActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "batch",
"version": "v1",
"kind": "CronJob"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useCronJobActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"version": "v1",
"kind": "Pod"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.usePodActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "ReplicaSet"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useReplicaSetActionsProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"version": "v1",
"kind": "ReplicationController"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useReplicationControllerActionsProvider))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/cluster-configuration",
"/cluster-configuration/:group"
],
"component": applyCodeRefSymbol(() => import('@console/app/src/components/cluster-configuration' /* webpackChunkName: 'app/code-refs/clusterConfiguration' */).then((m) => m.ClusterConfigurationPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/cluster-configuration",
"/cluster-configuration/:group"
],
"component": applyCodeRefSymbol(() => import('@console/app/src/components/cluster-configuration' /* webpackChunkName: 'app/code-refs/clusterConfiguration' */).then((m) => m.ClusterConfigurationPage))
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/app/src/actions' /* webpackChunkName: 'app/code-refs/actions' */).then((m) => m.useDeveloperPerspectiveStateProvider))
}
},
{
"type": "console.cluster-configuration/group",
"properties": {
"id": "general",
"label": "%console-app~General%"
}
},
{
"type": "console.cluster-configuration/group",
"properties": {
"id": "projects",
"label": "%console-app~Projects%",
"insertAfter": "general"
}
},
{
"type": "console.cluster-configuration/group",
"properties": {
"id": "developer",
"label": "%console-app~Developer%",
"insertAfter": "projects"
},
"flags": {
"required": [
"DEVELOPER_PERSPECTIVE"
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "console-app.customization.perspectives",
"groupId": "general",
"label": "%console-app~Perspectives%",
"description": "%console-app~Show or hide perspectives by enabling, disabling or adding access review checks.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/detect-perspective/PerspectiveConfiguration.tsx' /* webpackChunkName: 'app/code-refs/perspectiveConfiguration' */).then((m) => m.default))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "console-app.quick-start.QuickStartConfiguration",
"label": "%console-app~Quick Starts%",
"groupId": "general",
"description": "%console-app~Configure a list of Quick Starts that are not shown to users.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/quick-starts/QuickStartConfiguration.tsx' /* webpackChunkName: 'app/code-refs/quickStartConfiguration' */).then((m) => m.default))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
},
{
"group": "console.openshift.io/v1",
"resource": "ConsoleQuickStart",
"verb": "list"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
}
},
{
"type": "console.user-preference/group",
"properties": {
"id": "general",
"label": "%console-app~General%"
}
},
{
"type": "console.user-preference/group",
"properties": {
"id": "language",
"label": "%console-app~Language%"
}
},
{
"type": "console.user-preference/group",
"properties": {
"id": "notifications",
"label": "%console-app~Notifications%"
}
},
{
"type": "console.user-preference/group",
"properties": {
"id": "applications",
"label": "%console-app~Applications%"
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.theme",
"label": "%console-app~Theme%",
"groupId": "general",
"description": "%console-app~Console will appear as per the selected theme.%",
"field": {
"type": "dropdown",
"userSettingsKey": "console.theme",
"defaultValue": "systemDefault",
"options": [
{
"value": "systemDefault",
"label": "%console-app~System default%"
},
{
"value": "light",
"label": "%console-app~Light%"
},
{
"value": "dark",
"label": "%console-app~Dark%"
}
]
}
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.preferredPerspective",
"label": "%console-app~Perspective%",
"groupId": "general",
"description": "%console-app~If a perspective is not selected, the console defaults to the last viewed.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/user-preferences' /* webpackChunkName: 'app/code-refs/userPreferences' */).then((m) => m.PreferredPerspectiveSelect))
},
"insertAfter": "console.theme"
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.namespace.favorite",
"label": "%console-app~Project%",
"groupId": "general",
"description": "%console-app~If a project is not selected, the console defaults to the last viewed.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/user-preferences' /* webpackChunkName: 'app/code-refs/userPreferences' */).then((m) => m.NamespaceDropdown))
},
"insertBefore": "topology.preferredView"
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.preferredCreateEditMethod",
"label": "%console-app~Create/Edit resource method%",
"groupId": "general",
"description": "%console-app~If both form and YAML are available, the console defaults to your selection.%",
"field": {
"type": "dropdown",
"userSettingsKey": "console.preferredCreateEditMethod",
"defaultValue": "latest",
"options": [
{
"value": "latest",
"label": "%console-app~Last viewed%"
},
{
"value": "form",
"label": "%console-app~Form%"
},
{
"value": "yaml",
"label": "%console-app~YAML%"
}
]
},
"insertAfter": "topology.preferredView"
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.enableExactSearch",
"label": "%console-app~Search%",
"groupId": "general",
"description": "%console-app~Enable exact string match filtering on resources.%",
"field": {
"type": "checkbox",
"userSettingsKey": "console.enableExactSearch",
"label": "%console-app~Enable exact string match%",
"trueValue": true,
"falseValue": false,
"defaultValue": false
}
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.hideLightspeedButton",
"label": "%console-app~Lightspeed%",
"groupId": "general",
"description": "%console-app~Do not display the Lightspeed button.%",
"field": {
"type": "checkbox",
"userSettingsKey": "console.hideLightspeedButton",
"label": "%console-app~Hide Lightspeed%",
"trueValue": true,
"falseValue": false,
"defaultValue": false
},
"insertAfter": "console.preferredPerspective"
},
"flags": {
"required": [
"CONSOLE_CAPABILITY_LIGHTSPEEDBUTTON_IS_ENABLED",
"LIGHTSPEED_IS_AVAILABLE_TO_INSTALL"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.preferredLanguage",
"label": "%console-app~Language%",
"groupId": "language",
"description": "%console-app~Select the language you want to use for the console.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/user-preferences' /* webpackChunkName: 'app/code-refs/userPreferences' */).then((m) => m.LanguageDropdown))
}
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.hideUserWorkloadNotifications",
"label": "%console-app~User workload notifications%",
"groupId": "notifications",
"description": "%console-app~Do not display notifications created by users for specific projects on the cluster overview page or notification drawer.%",
"field": {
"type": "checkbox",
"userSettingsKey": "console.hideUserWorkloadNotifications",
"label": "%console-app~Hide user workload notifications%",
"trueValue": true,
"falseValue": false,
"defaultValue": false
}
},
"flags": {
"required": [
"PROMETHEUS",
"MONITORING",
"CAN_GET_NS"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "home",
"name": "%console-app~Home%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-home"
}
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "operators",
"name": "%console-app~Operators%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-operators"
},
"insertAfter": "home"
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "workloads",
"name": "%console-app~Workloads%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-workloads"
},
"insertAfter": "operators"
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "networking",
"name": "%console-app~Networking%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-networking"
},
"insertAfter": "workloads"
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "storage",
"name": "%console-app~Storage%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-storage"
}
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "builds",
"name": "%console-app~Builds%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-builds"
}
},
"flags": {
"required": [
"OPENSHIFT"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "compute",
"name": "%console-app~Compute%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-compute"
}
},
"flags": {
"required": [
"CAN_LIST_NODE"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "usermanagement",
"name": "%console-app~User Management%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-usermanagement"
}
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "administration",
"name": "%console-app~Administration%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-administration"
}
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "observe",
"insertBefore": [
"compute",
"usermanagement"
],
"name": "%console-app~Observe%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-monitoring"
}
},
"flags": {
"required": [
"PROMETHEUS",
"MONITORING",
"CAN_GET_NS"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "home",
"id": "dashboards",
"name": "%console-app~Overview%",
"href": "/dashboards"
},
"flags": {
"required": [
"CAN_GET_NS",
"OPENSHIFT",
"PROMETHEUS",
"MONITORING"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "home",
"id": "projects",
"insertAfter": "dashboards",
"name": "%console-app~Projects%",
"model": {
"group": "project.openshift.io",
"version": "v1",
"kind": "Project"
}
},
"flags": {
"required": [
"OPENSHIFT"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "home",
"id": "search",
"insertAfter": "projects",
"name": "%console-app~Search%",
"href": "/search",
"namespaced": true
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "home",
"id": "explore",
"insertAfter": "search",
"name": "%console-app~API Explorer%",
"href": "/api-explorer",
"startsWith": [
"api-resource"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "home",
"id": "events",
"insertAfter": "explore",
"name": "%console-app~Events%",
"href": "/events",
"prefixNamespaced": true
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "pods",
"insertAfter": "topology",
"name": "%console-app~Pods%",
"model": {
"version": "v1",
"kind": "Pod"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "deployments",
"insertAfter": "pods",
"name": "%console-app~Deployments%",
"model": {
"group": "apps",
"version": "v1",
"kind": "Deployment"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "deploymentconfigs",
"insertAfter": "deployments",
"name": "%console-app~DeploymentConfigs%",
"model": {
"group": "apps.openshift.io",
"version": "v1",
"kind": "DeploymentConfig"
}
},
"flags": {
"required": [
"OPENSHIFT_DEPLOYMENTCONFIG"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "statefulsets",
"insertAfter": "deploymentconfigs",
"name": "%console-app~StatefulSets%",
"model": {
"group": "apps",
"version": "v1",
"kind": "StatefulSet"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "secrets",
"insertAfter": "statefulsets",
"name": "%console-app~Secrets%",
"model": {
"version": "v1",
"kind": "Secret"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "configmaps",
"insertAfter": "secrets",
"name": "%console-app~ConfigMaps%",
"model": {
"version": "v1",
"kind": "ConfigMap"
}
}
},
{
"type": "console.navigation/separator",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "WorkloadsSeparator",
"insertAfter": "configmaps",
"testID": "WorkloadsSeparator"
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "cronjobs",
"insertAfter": "WorkloadsSeparator",
"name": "%console-app~CronJobs%",
"model": {
"group": "batch",
"version": "v1",
"kind": "CronJob"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "jobs",
"insertAfter": "cronjobs",
"name": "%console-app~Jobs%",
"model": {
"group": "batch",
"version": "v1",
"kind": "Job"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "daemonsets",
"insertAfter": "jobs",
"name": "%console-app~DaemonSets%",
"model": {
"group": "apps",
"version": "v1",
"kind": "DaemonSet"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "replicasets",
"insertAfter": "daemonsets",
"name": "%console-app~ReplicaSets%",
"model": {
"group": "apps",
"version": "v1",
"kind": "ReplicaSet"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "replicationcontrollers",
"insertAfter": "replicasets",
"name": "%console-app~ReplicationControllers%",
"model": {
"version": "v1",
"kind": "ReplicationController"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "horizontalpodautoscalers",
"insertAfter": "replicationcontrollers",
"name": "%console-app~HorizontalPodAutoscalers%",
"model": {
"group": "autoscaling",
"version": "v2",
"kind": "HorizontalPodAutoscaler"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "workloads",
"id": "poddisruptionbudgets",
"insertAfter": "horizontalpodautoscalers",
"name": "%console-app~PodDisruptionBudgets%",
"model": {
"group": "policy",
"version": "v1",
"kind": "PodDisruptionBudget"
}
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "storage",
"id": "persistentvolumes",
"name": "%console-app~PersistentVolumes%",
"model": {
"version": "v1",
"kind": "PersistentVolume"
}
},
"flags": {
"required": [
"CAN_LIST_PV"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "storage",
"id": "persistentvolumeclaims",
"insertAfter": "persistentvolumes",
"name": "%console-app~PersistentVolumeClaims%",
"model": {
"version": "v1",
"kind": "PersistentVolumeClaim"
}
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "storage",
"id": "storageclasses",
"insertAfter": "persistentvolumeclaims",
"name": "%console-app~StorageClasses%",
"model": {
"group": "storage.k8s.io",
"version": "v1",
"kind": "StorageClass"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "storage",
"id": "volumesnapshots",
"insertAfter": "storageclasses",
"name": "%console-app~VolumeSnapshots%",
"model": {
"group": "snapshot.storage.k8s.io",
"version": "v1",
"kind": "VolumeSnapshot"
}
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "storage",
"id": "volumesnapshotclasses",
"insertAfter": "volumesnapshots",
"name": "%console-app~VolumeSnapshotClasses%",
"model": {
"group": "snapshot.storage.k8s.io",
"version": "v1",
"kind": "VolumeSnapshotClass"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "builds",
"id": "buildconfigs",
"name": "%console-app~BuildConfigs%",
"model": {
"group": "build.openshift.io",
"version": "v1",
"kind": "BuildConfig"
}
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "builds",
"id": "builds",
"insertAfter": "buildconfigs",
"name": "%console-app~Builds%",
"model": {
"group": "build.openshift.io",
"version": "v1",
"kind": "Build"
}
},
"flags": {
"required": [
"OPENSHIFT_BUILD"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "builds",
"id": "imagestreams",
"insertAfter": "builds",
"name": "%console-app~ImageStreams%",
"startsWith": [
"imagestreamtags"
],
"model": {
"group": "image.openshift.io",
"version": "v1",
"kind": "ImageStream"
}
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "nodes",
"name": "%console-app~Nodes%",
"model": {
"version": "v1",
"kind": "Node"
}
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "machines",
"insertAfter": "nodes",
"name": "%console-app~Machines%",
"href": "/k8s/ns/openshift-machine-api/machine.openshift.io~v1beta1~Machine"
},
"flags": {
"required": [
"CLUSTER_API"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "machinesets",
"insertAfter": "machines",
"name": "%console-app~MachineSets%",
"href": "/k8s/ns/openshift-machine-api/machine.openshift.io~v1beta1~MachineSet"
},
"flags": {
"required": [
"CLUSTER_API"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "machzineautoscaler",
"insertAfter": "machinesets",
"name": "%console-app~MachineAutoscalers%",
"href": "/k8s/ns/openshift-machine-api/autoscaling.openshift.io~v1beta1~MachineAutoscaler"
},
"flags": {
"required": [
"MACHINE_AUTOSCALER"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "machinehealthchecks",
"insertAfter": "machzineautoscaler",
"name": "%console-app~MachineHealthChecks%",
"href": "/k8s/ns/openshift-machine-api/machine.openshift.io~v1beta1~MachineHealthCheck"
},
"flags": {
"required": [
"MACHINE_HEALTH_CHECK"
]
}
},
{
"type": "console.navigation/separator",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "computeseparator",
"insertAfter": "machinehealthchecks",
"testID": "ComputeSeparator"
},
"flags": {
"required": [
"MACHINE_CONFIG"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "machineconfigs",
"insertAfter": "computeseparator",
"name": "%console-app~MachineConfigs%",
"model": {
"group": "machineconfiguration.openshift.io",
"version": "v1",
"kind": "MachineConfig"
}
},
"flags": {
"required": [
"MACHINE_CONFIG"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "compute",
"id": "machineconfigpools",
"insertAfter": "machineconfigs",
"name": "%console-app~MachineConfigPools%",
"model": {
"group": "machineconfiguration.openshift.io",
"version": "v1",
"kind": "MachineConfigPool"
}
},
"flags": {
"required": [
"MACHINE_CONFIG"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "usermanagement",
"id": "users",
"name": "%console-app~Users%",
"model": {
"group": "user.openshift.io",
"version": "v1",
"kind": "User"
}
},
"flags": {
"required": [
"OPENSHIFT",
"OPENSHIFT_USER_API",
"CAN_LIST_USERS"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "usermanagement",
"id": "groups",
"insertAfter": "users",
"name": "%console-app~Groups%",
"model": {
"group": "user.openshift.io",
"version": "v1",
"kind": "Group"
}
},
"flags": {
"required": [
"OPENSHIFT",
"OPENSHIFT_GROUP_API",
"CAN_LIST_GROUPS"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "usermanagement",
"id": "serviceaccounts",
"insertAfter": "groups",
"name": "%console-app~ServiceAccounts%",
"model": {
"version": "v1",
"kind": "ServiceAccount"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "usermanagement",
"id": "roles",
"insertAfter": "serviceaccounts",
"name": "%console-app~Roles%",
"startsWith": [
"clusterroles"
],
"model": {
"group": "rbac.authorization.k8s.io",
"version": "v1",
"kind": "Role"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "usermanagement",
"id": "rolebindings",
"insertAfter": "roles",
"name": "%console-app~RoleBindings%",
"startsWith": [
"clusterrolebindings"
],
"model": {
"group": "rbac.authorization.k8s.io",
"version": "v1",
"kind": "RoleBinding"
}
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "administration",
"id": "clustersettings",
"name": "%console-app~Cluster Settings%",
"href": "/settings/cluster",
"startsWith": [
"settings/idp",
"config.openshift.io",
"monitoring/alertmanagerconfig",
"monitoring/alertmanageryaml"
]
},
"flags": {
"required": [
"CLUSTER_VERSION"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "administration",
"id": "namespaces",
"insertAfter": "clustersettings",
"name": "%console-app~Namespaces%",
"model": {
"version": "v1",
"kind": "Namespace"
}
},
"flags": {
"required": [
"CAN_LIST_NS"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "administration",
"id": "resourcequotas",
"insertAfter": "namespaces",
"name": "%console-app~ResourceQuotas%",
"startsWith": [
"clusterresourcequotas"
],
"model": {
"version": "v1",
"kind": "ResourceQuota"
}
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"perspective": "admin",
"section": "administration",
"id": "limitranges",
"insertAfter": "resourcequotas",
"name": "%console-app~LimitRanges%",
"model": {
"version": "v1",
"kind": "LimitRange"
}
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"perspective": "admin",
"section": "administration",
"id": "customresourcedefinitions",
"insertAfter": "limitranges",
"name": "%console-app~CustomResourceDefinitions%",
"model": {
"group": "apiextensions.k8s.io",
"version": "v1",
"kind": "CustomResourceDefinition"
}
},
"flags": {
"required": [
"CAN_LIST_CRD"
]
}
},
{
"type": "console.dashboards/overview/health/resource",
"properties": {
"title": "%console-app~Dynamic Plugins%",
"resources": applyCodeRefSymbol(() => import('@console/app/src/components/dashboards-page/dynamic-plugins-health-resource' /* webpackChunkName: 'app/code-refs/dynamicPluginsHealthResource' */).then((m) => m.dynamicPluginsResources)),
"healthHandler": applyCodeRefSymbol(() => import('@console/app/src/components/dashboards-page/dynamic-plugins-health-resource' /* webpackChunkName: 'app/code-refs/dynamicPluginsHealthResource' */).then((m) => m.getDynamicPluginHealthState)),
"popupComponent": applyCodeRefSymbol(() => import('@console/app/src/components/dashboards-page/dynamic-plugins-health-resource' /* webpackChunkName: 'app/code-refs/dynamicPluginsHealthResource' */).then((m) => m.DynamicPluginsPopover)),
"popupTitle": "%console-app~Dynamic Plugin status%"
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"CSI": {
"title": "%console-app~AWS CSI%",
"provisioner": "ebs.csi.aws.com",
"allowVolumeExpansion": true,
"parameters": {
"type": {
"name": "%console-app~Type%",
"values": {
"gp3": "gp3",
"gp2": "gp2",
"io1": "io1",
"sc1": "sc1",
"st1": "st1",
"standard": "standard"
},
"hintText": "%console-app~Select AWS Type. Default is gp3%"
},
"iopsPerGB": {
"name": "%console-app~IOPS per GiB%",
"hintText": "%console-app~I/O operations per second per GiB%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateAWSIopsPerGB)),
"visible": true
},
"fsType": {
"name": "%console-app~Filesystem Type%",
"hintText": "%console-app~Filesystem type to use during volume creation. Default is ext4.%",
"values": {
"ext4": "ext4",
"xfs": "xfs",
"ext2": "ext2",
"ext3": "ext3"
}
},
"encrypted": {
"name": "%console-app~Encrypted%",
"type": "checkbox",
"format": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.formatToString))
},
"kmsKeyId": {
"name": "%console-app~KMS key ID%",
"hintText": "%console-app~The full Amazon resource name of the key to use when encrypting the volume%",
"visible": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.isAWSKmsKeyIdVisible))
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~Local%",
"provisioner": "kubernetes.io/no-provisioner",
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.LOCAL)),
"volumeBindingMode": "WaitForFirstConsumer"
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~AWS Elastic Block Storage%",
"provisioner": "kubernetes.io/aws-ebs",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.AWS_EBS)),
"parameters": {
"type": {
"name": "%console-app~Type%",
"values": {
"io1": "io1",
"gp2": "gp2",
"sc1": "sc1",
"st1": "st1"
},
"hintText": "%console-app~Select AWS Type%"
},
"iopsPerGB": {
"name": "%console-app~IOPS per GiB%",
"hintText": "%console-app~I/O operations per second per GiB%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateAWSIopsPerGB)),
"visible": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.isAWSIopsPerGBVisible))
},
"fsType": {
"name": "%console-app~Filesystem type%",
"hintText": "%console-app~Filesystem type to use during volume creation. Default is ext4.%"
},
"encrypted": {
"name": "%console-app~Encrypted%",
"type": "checkbox",
"format": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.formatToString))
},
"kmsKeyId": {
"name": "%console-app~KMS key ID%",
"hintText": "%console-app~The full Amazon resource name of the key to use when encrypting the volume%",
"visible": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.isAWSKmsKeyIdVisible))
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~GCE PD%",
"provisioner": "kubernetes.io/gce-pd",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.GCE)),
"parameters": {
"type": {
"name": "%console-app~Type%",
"values": {
"pd-standard": "pd-standard",
"pd-ssd": "pd-ssd"
},
"hintText": "%console-app~Select GCE type%"
},
"zone": {
"name": "%console-app~Zone%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateGcePdZone))
},
"zones": {
"name": "%console-app~Zones%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateGcePdZones))
},
"replication-type": {
"name": "%console-app~Replication type%",
"values": {
"none": "none",
"regional-pd": "regional-pd"
},
"hintText": "%console-app~Select Replication type%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateGCEReplicationType))
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~Glusterfs%",
"provisioner": "kubernetes.io/glusterfs",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.GLUSTER)),
"parameters": {
"resturl": {
"name": "%console-app~Gluster REST/Heketi URL%",
"required": true
},
"restuser": {
"name": "%console-app~Gluster REST/Heketi user%"
},
"secretNamespace": {
"name": "%console-app~Secret Namespace%"
},
"secretName": {
"name": "%console-app~Secret name%"
},
"clusterid": {
"name": "%console-app~Cluster ID%"
},
"gidMin": {
"name": "%console-app~GID min%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateGlusterGidMin))
},
"gidMax": {
"name": "%console-app~GID max%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validateGlusterGidMax))
},
"volumetype": {
"name": "%console-app~Volume type%"
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~OpenStack Cinder%",
"provisioner": "kubernetes.io/cinder",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.OPENSTACK_CINDER)),
"parameters": {
"type": {
"name": "%console-app~Volume type%"
},
"availability": {
"name": "%console-app~Availability zone%"
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~Azure File%",
"provisioner": "kubernetes.io/azure-file",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.AZURE_FILE)),
"parameters": {
"skuName": {
"name": "%console-app~SKU name%",
"hintText": "%console-app~Azure storage account SKU tier%"
},
"location": {
"name": "%console-app~Location%",
"hintText": "%console-app~Azure storage account location%"
},
"storageAccount": {
"name": "%console-app~Azure storage account name%",
"hintText": "%console-app~Azure storage account name%"
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~Azure Disk%",
"provisioner": "kubernetes.io/azure-disk",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.AZURE_DISK)),
"parameters": {
"storageaccounttype": {
"name": "%console-app~Storage account type%",
"hintText": "%console-app~Storage account type%"
},
"kind": {
"name": "%console-app~Account kind%",
"values": {
"shared": "shared",
"dedicated": "dedicated",
"managed": "managed"
},
"hintText": "%console-app~Select account kind%"
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~Quobyte%",
"provisioner": "kubernetes.io/quobyte",
"allowVolumeExpansion": false,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.QUOBYTE)),
"parameters": {
"quobyteAPIServer": {
"name": "%console-app~Quobyte API server%",
"hintText": "%console-app~Quobyte API server%"
},
"registry": {
"name": "%console-app~Registry address(es)%",
"hintText": "%console-app~Registry address(es)%"
},
"adminSecretName": {
"name": "%console-app~Admin secret name%",
"hintText": "%console-app~Admin secret name%"
},
"adminSecretNamespace": {
"name": "%console-app~Admin secret namespace%",
"hintText": "%console-app~Admin secret namespace%"
},
"user": {
"name": "%console-app~User%",
"hintText": "%console-app~User%"
},
"group": {
"name": "%console-app~Group%",
"hintText": "%console-app~Group%"
},
"quobyteConfig": {
"name": "%console-app~Quobyte configuration%",
"hintText": "%console-app~Quobyte configuration%"
},
"quobyteTenant": {
"name": "%console-app~Quobyte tenant%",
"hintText": "%console-app~Quobyte tenant ID used to create/delete the volume%"
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~vSphere Volume%",
"provisioner": "kubernetes.io/vsphere-volume",
"allowVolumeExpansion": false,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.VSPHERE)),
"parameters": {
"diskformat": {
"name": "%console-app~Disk format%",
"values": {
"thin": "thin",
"zeroedthick": "zeroed thick",
"eagerzeroedthick": "eager zeroed thick"
},
"hintText": "%console-app~Select disk format%"
},
"datastore": {
"name": "%console-app~Datastore%",
"hintText": "%console-app~Datastore%"
}
},
"volumeBindingMode": "Immediate"
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "%console-app~Portworx Volume%",
"provisioner": "kubernetes.io/portworx-volume",
"allowVolumeExpansion": true,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.PORTWORX_VOLUME)),
"parameters": {
"fs": {
"name": "%console-app~Filesystem%",
"values": {
"none": "none",
"xfs": "xfs",
"ext4": "ext4"
},
"hintText": "%console-app~Select Filesystem%"
},
"block_size": {
"name": "%console-app~Block size%",
"hintText": "%console-app~Block size in Kb%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validatePortworxBlockSize))
},
"repl": {
"name": "%console-app~Number of synchronous replicas to be provided in the form of replication factor%",
"hintText": "%console-app~Number of replicas%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validatePortworxReplicas))
},
"io_priority": {
"name": "%console-app~I/O priority%",
"values": {
"high": "high",
"medium": "medium",
"low": "low"
},
"hintText": "%console-app~I/O priority%"
},
"snap_interval": {
"name": "%console-app~Snapshot interval%",
"hintText": "%console-app~Clock/time interval in minutes for when to trigger snapshots%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validatePortworxSnapshotInterval)),
"format": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.formatToString))
},
"aggregation_level": {
"name": "%console-app~Aggregation level%",
"hintText": "%console-app~The number of chunks the volume would be distributed into%",
"validation": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.validatePortworxAggregationLevel)),
"format": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.formatToString))
},
"ephemeral": {
"name": "%console-app~Ephemeral%",
"type": "checkbox",
"format": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.formatToString))
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "ScaleIO",
"provisioner": "kubernetes.io/scaleio",
"allowVolumeExpansion": false,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.SCALEIO)),
"parameters": {
"gateway": {
"name": "%console-app~API gateway%",
"required": true,
"hintText": "%console-app~ScaleIO API gateway address%"
},
"system": {
"name": "%console-app~System name%",
"required": true,
"hintText": "%console-app~Name of the ScaleIO system%"
},
"protectionDomain": {
"name": "%console-app~Protection domain%",
"required": true,
"hintText": "%console-app~Name of the ScaleIO protection domain%"
},
"storagePool": {
"name": "%console-app~Storage pool%",
"required": true,
"hintText": "%console-app~Name of the volume storage pool%"
},
"storageMode": {
"name": "%console-app~Storage mode%",
"values": {
"thinProvisioned": "ThinProvisioned",
"thickProvisioned": "ThickProvisioned"
},
"hintText": "%console-app~Select storage provision mode%"
},
"secretRef": {
"name": "%console-app~Secret reference%",
"required": true,
"hintText": "%console-app~Reference to a configured Secret object%"
},
"readOnly": {
"name": "%console-app~Read Only%",
"type": "checkbox",
"format": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.formatToString))
},
"fsType": {
"name": "%console-app~Filesystem Type%",
"hintText": "%console-app~Filesystem to use for the volume%"
}
}
}
}
},
{
"type": "console.storage-class/provisioner",
"properties": {
"OTHERS": {
"title": "StorageOS",
"provisioner": "kubernetes.io/storageos",
"allowVolumeExpansion": false,
"documentationLink": applyCodeRefSymbol(() => import('@console/app/src/components/storage/Documentation.ts' /* webpackChunkName: 'app/code-refs/storageProvisionerDocs' */).then((m) => m.STORAGEOS)),
"parameters": {
"pool": {
"name": "%console-app~Pool%",
"hintText": "%console-app~Name of the StorageOS distributed capacity pool from which to provision the volume%"
},
"description": {
"name": "%console-app~Description%",
"hintText": "%console-app~Description to assign to volumes that were created dynamically%"
},
"fsType": {
"name": "%console-app~Filesystem type%",
"hintText": "%console-app~Default filesystem type to request%"
},
"adminSecretName": {
"name": "%console-app~Admin secret name%",
"hintText": "%console-app~Name of the secret to use for obtaining the StorageOS API credentials%"
},
"adminSecretNamespace": {
"name": "%console-app~Admin secret namespace%",
"hintText": "%console-app~Namespace where the API configuration secret is located%",
"required": applyCodeRefSymbol(() => import('@console/app/src/components/storage/StorageClassProviders.ts' /* webpackChunkName: 'app/code-refs/storageProvisioners' */).then((m) => m.isStorageOsAdminSecretNSRequired))
}
}
}
}
},
{
"type": "console.node/status",
"properties": {
"title": "%console-app~Scheduling disabled%",
"PopoverContent": applyCodeRefSymbol(() => import('@console/app/src/components/nodes/status' /* webpackChunkName: 'app/code-refs/nodeStatus' */).then((m) => m.MarkAsSchedulablePopover)),
"isActive": applyCodeRefSymbol(() => import('@console/app/src/components/nodes/status' /* webpackChunkName: 'app/code-refs/nodeStatus' */).then((m) => m.isUnschedulableActive))
}
},
{
"type": "console.node/status",
"properties": {
"title": "%console-app~Approval required%",
"PopoverContent": applyCodeRefSymbol(() => import('@console/app/src/components/nodes/status' /* webpackChunkName: 'app/code-refs/nodeStatus' */).then((m) => m.ServerCSRPopoverContent)),
"isActive": applyCodeRefSymbol(() => import('@console/app/src/components/nodes/status' /* webpackChunkName: 'app/code-refs/nodeStatus' */).then((m) => m.isCSRActive)),
"resources": {
"csrs": {
"groupVersionKind": {
"group": "certificates.k8s.io",
"kind": "CertificateSigningRequest",
"version": "v1"
},
"isList": true
}
}
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"version": "v1",
"kind": "Node"
},
"provider": applyCodeRefSymbol(() => import('@console/app/src/components/nodes/menu-actions.tsx' /* webpackChunkName: 'app/code-refs/nodeActions' */).then((m) => m.useNodeActions))
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-display-name",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "left",
"title": "%console-app~Display Name%",
"path": "spec.displayName",
"sortWeight": 0
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-description",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "left",
"title": "%console-app~Description%",
"description": "%console-app~Information describing the plugin.%",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginDescriptionDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginDescriptionDetail' */).then((m) => m.default)),
"sortWeight": 1
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-version",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "right",
"title": "%console-app~Version%",
"description": "%console-app~The specific update of the plugin.%",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginVersionDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginVersionDetail' */).then((m) => m.default)),
"sortWeight": 0
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-status",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "right",
"title": "%console-app~Status%",
"description": "%console-app~The state of the plugin.%",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginStatusDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginStatusDetail' */).then((m) => m.default)),
"sortWeight": 1
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-enabled",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "right",
"title": "%console-app~Enabled%",
"description": "%console-app~Whether or not the plugin renders in console.%",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginEnabledStatusDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginEnabledStatusDetail' */).then((m) => m.default)),
"sortWeight": 2
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-csp",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "right",
"title": "%console-app~CSP Violations%",
"description": "%console-app~Whether or not the plugin might have violated the Console Content Security Policy.%",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginCSPStatusDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginCSPStatusDetail' */).then((m) => m.default)),
"sortWeight": 3
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-backend",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "right",
"title": "%console-app~Backend Service%",
"path": "spec.backend.service",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginBackendDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginBackendDetail' */).then((m) => m.default)),
"sortWeight": 4
}
},
{
"type": "console.resource/details-item",
"properties": {
"id": "plugin-proxy",
"model": {
"group": "console.openshift.io",
"version": "v1",
"kind": "ConsolePlugin"
},
"column": "right",
"title": "%console-app~Proxy Services%",
"path": "spec.proxy",
"component": applyCodeRefSymbol(() => import('@console/app/src/components/console-operator/ConsolePluginProxyDetail.tsx' /* webpackChunkName: 'app/code-refs/consolePluginProxyDetail' */).then((m) => m.default)),
"sortWeight": 5
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/app/src/hooks/useCanGetConsoleOperatorConfig.ts' /* webpackChunkName: 'app/code-refs/getConsoleOperatorConfigFlag' */).then((m) => m.default))
}
},
{
"type": "console.navigation/href",
"properties": {
"perspective": "admin",
"section": "administration",
"id": "consoleplugins",
"name": "%console-app~Dynamic Plugins%",
"href": "/k8s/cluster/operator.openshift.io~v1~Console/cluster/console-plugins"
},
"flags": {
"required": [
"CAN_GET_CONSOLE_OPERATOR_CONFIG"
]
}
}
],
],
});
activePlugins.push({
name: '@console/telemetry-plugin',
extensions: [
...require('@console/telemetry-plugin/src/plugin.ts').default,
...[
{
"type": "console.flag",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/flags' /* webpackChunkName: 'telemetry-plugin/code-refs/telemetryFlags' */).then((m) => m.detectTelemetry))
}
},
{
"type": "console.telemetry/listener",
"properties": {
"listener": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/listeners' /* webpackChunkName: 'telemetry-plugin/code-refs/telemetryListeners' */).then((m) => m.debug))
},
"flags": {
"required": [
"TELEMETRY_DEBUG"
]
}
},
{
"type": "console.telemetry/listener",
"properties": {
"listener": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/listeners' /* webpackChunkName: 'telemetry-plugin/code-refs/telemetryListeners' */).then((m) => m.usage))
},
"flags": {
"required": [
"TELEMETRY"
]
}
},
{
"type": "console.telemetry/listener",
"properties": {
"listener": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/listeners' /* webpackChunkName: 'telemetry-plugin/code-refs/telemetryListeners' */).then((m) => m.segment))
},
"flags": {
"required": [
"TELEMETRY"
]
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/providers' /* webpackChunkName: 'telemetry-plugin/code-refs/providers' */).then((m) => m.useTelemetryProvider))
}
},
{
"type": "console.cluster-configuration/group",
"properties": {
"id": "telemetry",
"label": "%console-telemetry-plugin~Telemetry%",
"insertAfter": "webTerminal"
},
"flags": {
"required": [
"TELEMETRY_ENABLED"
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "telemetry.telemetryConfiguration",
"groupId": "telemetry",
"label": "%console-telemetry-plugin~Telemetry%",
"description": "%console-telemetry-plugin~Configure telemetry setting%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/components/TelemetryConfiguration.tsx' /* webpackChunkName: 'telemetry-plugin/code-refs/telemetryConfiguration' */).then((m) => m.default))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
},
"flags": {
"required": [
"TELEMETRY_CLUSTER_CONFIGURATION"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "console.telemetryAnalytics",
"groupId": "general",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/telemetry-plugin/src/components/TelemetryUserPreferenceDropdown.tsx' /* webpackChunkName: 'telemetry-plugin/code-refs/telemetryUserPreferenceDropdown' */).then((m) => m.default))
},
"insertAfter": "console.preferredCreateEditMethod"
},
"flags": {
"required": [
"TELEMETRY_USER_PREFERENCE"
]
}
}
],
],
});
activePlugins.push({
name: '@console/container-security',
extensions: [
...require('@console/container-security/src/plugin.ts').default,
...[
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "secscan.quay.redhat.com",
"version": "v1alpha1",
"kind": "ImageManifestVuln"
},
"flag": "SECURITY_LABELLER"
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"id": "imagevulnerabilities",
"perspective": "admin",
"section": "administration",
"name": "Image Vulnerabilities",
"insertBefore": "customresourcedefinitions",
"model": {
"group": "secscan.quay.redhat.com",
"kind": "ImageManifestVuln",
"version": "v1alpha1"
},
"dataAttributes": {
"data-test-id": "imagemanifestvuln-header"
}
},
"flags": {
"required": [
"SECURITY_LABELLER"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"version": "v1",
"kind": "Pod"
},
"page": {
"name": "%container-security~Vulnerabilities%",
"href": "vulnerabilities"
},
"component": applyCodeRefSymbol(() => import('@console/container-security/src/components' /* webpackChunkName: 'container-security/code-refs/containerSecurityComponent' */).then((m) => m.ImageManifestVulnPodTab))
},
"flags": {
"required": [
"SECURITY_LABELLER"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "project.openshift.io",
"version": "v1",
"kind": "Project"
},
"page": {
"name": "%container-security~Vulnerabilities%",
"href": "vulnerabilities"
},
"component": applyCodeRefSymbol(() => import('@console/container-security/src/components' /* webpackChunkName: 'container-security/code-refs/containerSecurityComponent' */).then((m) => m.ProjectImageManifestVulnListPage))
},
"flags": {
"required": [
"SECURITY_LABELLER"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"version": "v1",
"kind": "Namespace"
},
"page": {
"name": "%container-security~Vulnerabilities%",
"href": "vulnerabilities"
},
"component": applyCodeRefSymbol(() => import('@console/container-security/src/components' /* webpackChunkName: 'container-security/code-refs/containerSecurityComponent' */).then((m) => m.ProjectImageManifestVulnListPage))
},
"flags": {
"required": [
"SECURITY_LABELLER"
]
}
}
],
],
});
activePlugins.push({
name: '@console/dev-console',
extensions: [
...require('@console/dev-console/src/plugin.tsx').default,
...[
{
"type": "console.perspective",
"properties": {
"id": "dev",
"name": "%devconsole~Developer%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/perspective.tsx' /* webpackChunkName: 'dev-console/code-refs/perspective' */).then((m) => m.icon)),
"defaultPins": [
{
"group": "",
"version": "v1",
"kind": "ConfigMap"
},
{
"group": "",
"version": "v1",
"kind": "Secret"
}
],
"landingPageURL": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/perspective.tsx' /* webpackChunkName: 'dev-console/code-refs/perspective' */).then((m) => m.getLandingPageURL)),
"importRedirectURL": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/perspective.tsx' /* webpackChunkName: 'dev-console/code-refs/perspective' */).then((m) => m.getImportRedirectURL)),
"usePerspectiveDetection": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/perspective.tsx' /* webpackChunkName: 'dev-console/code-refs/perspective' */).then((m) => m.usePerspectiveDetection))
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Pipeline"
},
"flag": "OPENSHIFT_PIPELINE"
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "dev-console.project-access.ProjectAccessRolesConfiguration",
"groupId": "projects",
"label": "%devconsole~Project access Cluster Roles%",
"description": "%devconsole~Define a list of ClusterRole names that are assignable to users on the project access page.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/project-access' /* webpackChunkName: 'dev-console/code-refs/projectAccess' */).then((m) => m.ProjectAccessRolesConfiguration))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
},
{
"resource": "clusterroles",
"verb": "list"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "dev-console.PinnedResourcesConfiguration",
"groupId": "developer",
"label": "%devconsole~Pre-pinned navigation items%",
"description": "%devconsole~Resource links that are shown below the standard navigation items. These pinned resources can still be changed by the user.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.PinnedResourcesConfiguration))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "dev-console.AddPageConfiguration",
"groupId": "developer",
"label": "%devconsole~Add page actions%",
"description": "%devconsole~A list of actions that are not shown to users on the add page.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/add' /* webpackChunkName: 'dev-console/code-refs/add' */).then((m) => m.AddPageConfiguration))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "dev-console.CatalogTypesConfiguration",
"groupId": "developer",
"label": "%devconsole~Software Catalog Types%",
"description": "%devconsole~A list of software catalog types that are not shown to users.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.CatalogTypesConfiguration))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
}
]
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.useDeveloperCatalogProvider))
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "developer-catalog",
"name": "%devconsole~Software Catalog%",
"insertBefore": "serverless-function"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "eventing",
"name": "%devconsole~Eventing%",
"insertAfter": "developer-catalog"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "serverless-function",
"name": "%devconsole~Serverless function%",
"insertAfter": "eventing",
"insertBefore": "git-repository",
"icon": "icon-serverless-function"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "git-repository",
"name": "%devconsole~Git Repository%",
"insertBefore": "container-images",
"insertAfter": "serverless-function"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "container-images",
"name": "%devconsole~Container images%",
"insertAfter": "git-repository"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "local-machine",
"name": "%devconsole~From Local Machine%",
"insertBefore": "pipelines"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "samples",
"name": "%devconsole~Samples%",
"insertAfter": "pipelines"
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "application-portability",
"name": "%devconsole~Application portability%",
"insertBefore": "local-machine"
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "import-from-samples",
"groupId": "samples",
"href": "/samples/ns/:namespace",
"label": "%devconsole~Samples%",
"description": "%devconsole~Create an application from a code sample%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.samplesIconElement)),
"accessReview": [
{
"group": "build.openshift.io",
"resource": "buildconfigs",
"verb": "create"
},
{
"group": "image.openshift.io",
"resource": "imagestreams",
"verb": "create"
},
{
"group": "apps.openshift.io",
"resource": "deploymentconfigs",
"verb": "create"
},
{
"group": "",
"resource": "secrets",
"verb": "create"
},
{
"group": "route.openshift.io",
"resource": "routes",
"verb": "create"
},
{
"group": "",
"resource": "services",
"verb": "create"
}
]
},
"flags": {
"required": [
"DEVELOPER_CATALOG",
"SAMPLE_CATALOG_TYPE"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "import-from-git",
"groupId": "git-repository",
"href": "/import/ns/:namespace",
"label": "%devconsole~Import from Git%",
"description": "%devconsole~Import code from your Git repository to be built and deployed%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.gitIconElement)),
"accessReview": [
{
"group": "build.openshift.io",
"resource": "buildconfigs",
"verb": "create"
},
{
"group": "image.openshift.io",
"resource": "imagestreams",
"verb": "create"
},
{
"group": "apps.openshift.io",
"resource": "deploymentconfigs",
"verb": "create"
},
{
"group": "",
"resource": "secrets",
"verb": "create"
},
{
"group": "route.openshift.io",
"resource": "routes",
"verb": "create"
},
{
"group": "",
"resource": "services",
"verb": "create"
}
]
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "import-from-git",
"groupId": "git-repository",
"href": "/import/ns/:namespace",
"label": "%devconsole~Import from Git%",
"description": "%devconsole~Import code from your Git repository to be built and deployed%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.gitIconElement)),
"accessReview": [
{
"group": "apps.openshift.io",
"resource": "deploymentconfigs",
"verb": "create"
},
{
"group": "",
"resource": "secrets",
"verb": "create"
},
{
"group": "route.openshift.io",
"resource": "routes",
"verb": "create"
},
{
"group": "",
"resource": "services",
"verb": "create"
},
{
"group": "tekton.dev",
"resource": "pipelines",
"verb": "create"
}
]
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"OPENSHIFT_BUILDCONFIG"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "deploy-image",
"groupId": "container-images",
"href": "/deploy-image/ns/:namespace",
"label": "%devconsole~Container images%",
"description": "%devconsole~Deploy an existing Image from an Image registry or Image stream tag%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.deployIconElement)),
"accessReview": [
{
"group": "build.openshift.io",
"resource": "buildconfigs",
"verb": "create"
},
{
"group": "image.openshift.io",
"resource": "imagestreams",
"verb": "create"
},
{
"group": "apps.openshift.io",
"resource": "deploymentconfigs",
"verb": "create"
},
{
"group": "image.openshift.io",
"resource": "imagestreamimports",
"verb": "create"
},
{
"group": "",
"resource": "secrets",
"verb": "create"
},
{
"group": "route.openshift.io",
"resource": "routes",
"verb": "create"
},
{
"group": "",
"resource": "services",
"verb": "create"
}
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "import-yaml",
"groupId": "local-machine",
"href": "/k8s/ns/:namespace/import",
"label": "%devconsole~Import YAML%",
"description": "%devconsole~Create resources from their YAML or JSON definitions%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.yamlIconElement))
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "dev-catalog",
"groupId": "developer-catalog",
"href": "/catalog/ns/:namespace",
"label": "%devconsole~All services%",
"description": "%devconsole~Browse the catalog to discover, deploy and connect to services%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.devCatalogIconElement))
},
"flags": {
"required": [
"DEVELOPER_CATALOG"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "dev-catalog-databases",
"groupId": "developer-catalog",
"href": "/catalog/ns/:namespace?category=databases",
"label": "%devconsole~Database%",
"description": "%devconsole~Browse the catalog to discover database services to add to your application%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.databaseCatalogIconElement))
},
"flags": {
"required": [
"DEVELOPER_CATALOG"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "operator-backed",
"groupId": "developer-catalog",
"href": "/catalog/ns/:namespace?catalogType=OperatorBackedService",
"label": "%devconsole~Operator Backed%",
"description": "%devconsole~Browse the catalog to discover and deploy operator managed services%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.operatorCatalogIconElement))
},
"flags": {
"required": [
"DEVELOPER_CATALOG",
"OPERATOR_BACKED_SERVICE_CATALOG_TYPE"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "upload-jar",
"groupId": "local-machine",
"href": "/upload-jar/ns/:namespace",
"label": "%devconsole~Upload JAR file%",
"description": "%devconsole~Upload a JAR file from your local desktop to OpenShift%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.uploadJarIconElement)),
"accessReview": [
{
"group": "build.openshift.io",
"resource": "buildconfigs",
"verb": "create"
},
{
"group": "image.openshift.io",
"resource": "imagestreams",
"verb": "create"
},
{
"group": "apps.openshift.io",
"resource": "deploymentconfigs",
"verb": "create"
},
{
"group": "",
"resource": "secrets",
"verb": "create"
},
{
"group": "route.openshift.io",
"resource": "routes",
"verb": "create"
},
{
"group": "",
"resource": "services",
"verb": "create"
}
]
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"JAVA_IMAGE_STREAM_ENABLED"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "export-application",
"groupId": "application-portability",
"callback": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.exportApplicationAction)),
"label": "%devconsole~Export application%",
"description": "%devconsole~Replicate your application by exporting a zip file, which can be imported to another project or another cluster%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.exportApplicationIconElement)),
"accessReview": [
{
"group": "primer.gitops.io",
"resource": "exports",
"verb": "create"
}
]
},
"flags": {
"required": [
"ALLOW_EXPORT_APP"
]
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "sharing",
"name": "%devconsole~Sharing%",
"insertAfter": "helm-chart-repositories",
"insertBefore": "local-machine"
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "project-access",
"groupId": "sharing",
"href": "/project-access/ns/:namespace",
"label": "%devconsole~Sharing%",
"description": "%devconsole~Project access allows you to add or remove a user's access to the project%",
"icon": applyCodeRefSymbol(() => import('@console/dev-console/src/utils/icons.tsx' /* webpackChunkName: 'dev-console/code-refs/icons' */).then((m) => m.shareProjectIconElement)),
"accessReview": [
{
"group": "rbac.authorization.k8s.io",
"resource": "rolebindings",
"verb": "create"
},
{
"group": "rbac.authorization.k8s.io",
"resource": "rolebindings",
"verb": "list"
}
]
}
},
{
"type": "dev-console.import/environment",
"properties": {
"imageStreamName": "nodejs",
"imageStreamTags": [
"16-ubi8",
"14-ubi8",
"14-ubi8-minimal",
"12-ubi8",
"latest"
],
"environments": [
{
"key": "NPM_RUN",
"label": "%devconsole~Run command%",
"description": "%devconsole~Optional arguments for npm run.%",
"defaultValue": "start"
}
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "BuilderImage",
"title": "%devconsole~Builder Images%",
"catalogDescription": "%devconsole~Browse for container images that support a particular language or framework. Cluster administrators can customize the content made available in the catalog.%",
"typeDescription": "%devconsole~**Builder Images** are container images that build source code for a particular language or framework.%"
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "BuilderImage",
"title": "%devconsole~Builder Images%",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.builderImageProvider))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "Template",
"title": "%devconsole~Templates%",
"catalogDescription": "%devconsole~Browse for templates that can deploy services, create builds, or create any resources the template enables. Cluster administrators can customize the content made available in the catalog.%",
"typeDescription": "%devconsole~**Templates** are sets of objects for creating services, build configurations, and anything you have permission to create within a Project.%"
},
"flags": {
"required": [
"OPENSHIFT_TEMPLATE"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "Template",
"title": "%devconsole~Templates%",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.templateProvider))
},
"flags": {
"required": [
"OPENSHIFT_TEMPLATE"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "Devfile",
"title": "%devconsole~Devfiles%",
"catalogDescription": "%devconsole~Browse for devfiles that support a particular language or framework. Cluster administrators can customize the content made available in the catalog.%",
"typeDescription": "%devconsole~**Devfiles** are sets of objects for creating services, build configurations, and anything you have permission to create within a Project.%"
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "Devfile",
"title": "%devconsole~Devfiles%",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.devfileProvider))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.catalog/item-type-metadata",
"properties": {
"type": "OperatorBackedService",
"filters": [
{
"label": "%devconsole~Service binding%",
"attribute": "bindable",
"description": "%devconsole~Bindable services can be easily consumed by applications because they expose their binding data (credentials, connection details, volume mounts, secrets, etc.) in a standard way.\n\nService Bindings is deprecated with OpenShift 4.15.\n\nService Bindings can still be created and show in the OpenShift web console, but will be removed from a future release, currently planned as OpenShift Container Platform 4.18. You should migrate wherever it is possible.%"
}
]
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.catalog/item-metadata",
"properties": {
"catalogId": "dev-catalog",
"type": "OperatorBackedService",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.bindableItemMetadataProvider))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "Sample",
"title": "%devconsole~Samples%"
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "samples-catalog",
"type": "ConsoleSample",
"title": "%devconsole~Console sample%",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.useConsoleSamplesCatalogProvider))
},
"flags": {
"required": [
"CONSOLESAMPLE"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "samples-catalog",
"type": "BuilderImage",
"title": "%devconsole~Builder Image%",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.builderImageSamplesProvider))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "samples-catalog",
"type": "Devfile",
"title": "%devconsole~Devfile%",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.devfileSamplesProvider))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.file-upload",
"properties": {
"fileExtensions": [
"jar"
],
"handler": applyCodeRefSymbol(() => import('@console/dev-console/src/components/jar-file-upload' /* webpackChunkName: 'dev-console/code-refs/fileUpload' */).then((m) => m.jarFileUploadHandler))
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "add",
"perspective": "dev",
"section": "top",
"name": "%devconsole~+Add%",
"href": "/add",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-add",
"data-test-id": "+Add-header"
}
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "topology",
"perspective": "dev",
"section": "top",
"name": "%devconsole~Topology%",
"href": "/topology",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-topology",
"data-test-id": "topology-header"
}
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "topology",
"perspective": "admin",
"section": "workloads",
"name": "%devconsole~Topology%",
"href": "/topology",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-topology",
"data-test-id": "topology-header"
}
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "observe",
"perspective": "dev",
"section": "top",
"name": "%devconsole~Observe%",
"href": "/dev-monitoring",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-monitoring",
"data-tour-id": "tour-monitoring-nav",
"data-test-id": "monitoring-header"
}
},
"flags": {
"required": [
"MONITORING"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "search",
"perspective": "dev",
"section": "top",
"name": "%devconsole~Search%",
"href": "/search-page",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-search",
"data-tour-id": "tour-search-nav",
"data-test-id": "search-header"
}
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "project",
"perspective": "dev",
"section": "resources",
"name": "%devconsole~Project%",
"href": "/project-details",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-project",
"data-test-id": "project-details-header"
}
},
"flags": {
"required": [
"OPENSHIFT_PROJECT"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "builds",
"perspective": "dev",
"section": "resources",
"name": "%devconsole~Builds%",
"href": "/builds",
"namespaced": true,
"insertBefore": [
"helm",
"pipelines"
],
"dataAttributes": {
"data-quickstart-id": "qs-nav-builds",
"data-test-id": "build-header"
}
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "developer-catalog",
"perspective": "admin",
"section": "home",
"name": "%devconsole~Software Catalog%",
"href": "/catalog",
"namespaced": true,
"insertAfter": "search",
"dataAttributes": {
"data-test-id": "developer-catalog-header"
}
},
"flags": {
"required": [
"DEVELOPER_CATALOG"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/builds"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components' /* webpackChunkName: 'dev-console/code-refs/common' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/builds/all-namespaces",
"/builds/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/builds' /* webpackChunkName: 'dev-console/code-refs/builds' */).then((m) => m.BuildsTabListPage))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"id": "top",
"perspective": "dev"
}
},
{
"type": "console.navigation/section",
"properties": {
"id": "resources",
"perspective": "dev"
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "Deployment"
},
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.useEditImportActionProvider))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps.openshift.io",
"version": "v1",
"kind": "DeploymentConfig"
},
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.useEditImportActionProvider))
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-context-actions",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.useTopologyGraphActionProvider))
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-context-actions",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.useTopologyApplicationActionProvider))
}
},
{
"type": "console.action/group",
"properties": {
"id": "add-to-project",
"label": "%devconsole~Add to Project%",
"submenu": true
}
},
{
"type": "console.topology/component/factory",
"properties": {
"getFactory": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.getDevConsoleComponentFactory))
}
},
{
"type": "console.topology/relationship/provider",
"properties": {
"provides": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.providerProvidesServiceBinding)),
"tooltip": "%devconsole~Create Service Binding%",
"create": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.providerCreateServiceBinding)),
"priority": 100
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-hpas",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.useHpaTabSectionForTopologySideBar)),
"insertAfter": "topology-tab-section-builds-overview"
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-vpas",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.useVpaTabSectionForTopologySideBar)),
"insertAfter": "topology-tab-section-builds-overview"
}
},
{
"type": "console.topology/details/tab",
"properties": {
"id": "topology-side-bar-tab-observe",
"label": "%devconsole~Observe%",
"insertAfter": "topology-side-bar-tab-resource"
},
"flags": {
"required": [
"MONITORING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-observe",
"tab": "topology-side-bar-tab-observe",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.useObserveSideBarTabSection))
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 100,
"link": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.getSbrPanelResourceLink))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-sbr-details",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.useSbrPanelDetailsSection))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-sbr-resource",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.useSbrPanelResourceSection))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "binding.operators.coreos.com",
"version": "v1alpha1",
"kind": "ServiceBinding"
},
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/actions' /* webpackChunkName: 'dev-console/code-refs/actions' */).then((m) => m.useServiceBindingActionProvider))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.action/group",
"properties": {
"id": "add-to-application",
"label": "%devconsole~Add to application%",
"submenu": true,
"insertAfter": [
"delete-application"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "devconsole.routingOptions",
"label": "%devconsole~Security%",
"groupId": "applications",
"description": "",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/user-preferences' /* webpackChunkName: 'dev-console/code-refs/userPreferences' */).then((m) => m.SecureRouteFields))
},
"insertAfter": "console.preferredCreateEditMethod"
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "devconsole.preferredResource",
"label": "%devconsole~Resource type%",
"groupId": "applications",
"description": "%devconsole~If resource type is not selected, the console default to the latest.%",
"field": {
"type": "dropdown",
"userSettingsKey": "devconsole.preferredResourceType",
"defaultValue": "kubernetes",
"description": "%devconsole~The defaults below will only apply to the Import from Git and Deploy Image forms when creating Deployments or Deployment Configs.%",
"options": [
{
"value": "latest",
"label": "%devconsole~Last used%",
"description": "%devconsole~The last resource type used when adding a resource.%"
},
{
"value": "kubernetes",
"label": "%devconsole~Deployment%",
"description": "%devconsole~A Deployment enables declarative updates for Pods and ReplicaSets.%"
},
{
"value": "openshift",
"label": "%devconsole~DeploymentConfig%",
"description": "%devconsole~A DeploymentConfig defines the template for a Pod and manages deploying new Images or configuration changes.%"
}
]
},
"insertBefore": "devconsole.routingOptions"
},
"flags": {
"disallowed": [
"KNATIVE_SERVING_SERVICE"
],
"required": [
"OPENSHIFT_DEPLOYMENTCONFIG"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "devconsole.preferredResource",
"label": "%devconsole~Resource type%",
"groupId": "applications",
"description": "%devconsole~If resource type is not selected, the console default to the last used.%",
"field": {
"type": "dropdown",
"userSettingsKey": "devconsole.preferredResourceType",
"defaultValue": "knative",
"description": "%devconsole~The defaults below will only apply to the Import from Git and Deploy Image forms when creating Deployments, Deployment Configs or Serverless Deployment.%",
"options": [
{
"value": "latest",
"label": "%devconsole~Last used%",
"description": "%devconsole~The last resource type used when adding a resource.%"
},
{
"value": "kubernetes",
"label": "%devconsole~Deployment%",
"description": "%devconsole~A Deployment enables declarative updates for Pods and ReplicaSets.%"
},
{
"value": "openshift",
"label": "%devconsole~DeploymentConfig%",
"description": "%devconsole~A DeploymentConfig defines the template for a Pod and manages deploying new Images or configuration changes.%"
},
{
"value": "knative",
"label": "%devconsole~Serverless Deployment%",
"description": "%devconsole~A type of deployment that enables Serverless scaling to 0 when idle.%"
}
]
},
"insertBefore": "devconsole.routingOptions"
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"OPENSHIFT_DEPLOYMENTCONFIG"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "devconsole.preferredResource",
"label": "%devconsole~Resource type%",
"groupId": "applications",
"description": "%devconsole~If resource type is not selected, the console default to the latest.%",
"field": {
"type": "dropdown",
"userSettingsKey": "devconsole.preferredResourceType",
"defaultValue": "kubernetes",
"description": "%devconsole~The defaults below will only apply to the Import from Git and Deploy Image forms when creating Deployments or Deployment Configs.%",
"options": [
{
"value": "latest",
"label": "%devconsole~Last used%",
"description": "%devconsole~The last resource type used when adding a resource.%"
},
{
"value": "kubernetes",
"label": "%devconsole~Deployment%",
"description": "%devconsole~A Deployment enables declarative updates for Pods and ReplicaSets.%"
}
]
},
"insertBefore": "devconsole.routingOptions"
},
"flags": {
"disallowed": [
"OPENSHIFT_DEPLOYMENTCONFIG",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "devconsole.preferredResource",
"label": "%devconsole~Resource type%",
"groupId": "applications",
"description": "%devconsole~If resource type is not selected, the console default to the latest.%",
"field": {
"type": "dropdown",
"userSettingsKey": "devconsole.preferredResourceType",
"defaultValue": "kubernetes",
"description": "%devconsole~The defaults below will only apply to the Import from Git and Deploy Image forms when creating Deployments or Deployment Configs.%",
"options": [
{
"value": "latest",
"label": "%devconsole~Last used%",
"description": "%devconsole~The last resource type used when adding a resource.%"
},
{
"value": "kubernetes",
"label": "%devconsole~Deployment%",
"description": "%devconsole~A Deployment enables declarative updates for Pods and ReplicaSets.%"
},
{
"value": "knative",
"label": "%devconsole~Serverless Deployment%",
"description": "%devconsole~A type of deployment that enables Serverless scaling to 0 when idle.%"
}
]
},
"insertBefore": "devconsole.routingOptions"
},
"flags": {
"disallowed": [
"OPENSHIFT_DEPLOYMENTCONFIG"
],
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology/bindable-services' /* webpackChunkName: 'dev-console/code-refs/serviceBindingContext' */).then((m) => m.ServiceBindingContextProvider)),
"useValueHook": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology/bindable-services' /* webpackChunkName: 'dev-console/code-refs/serviceBindingContext' */).then((m) => m.useValuesServiceBindingContext))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/project-access/ns/:ns",
"/project-access/all-namespaces"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/project-access' /* webpackChunkName: 'dev-console/code-refs/projectAccess' */).then((m) => m.ProjectAccessPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/serverless-function/all-namespaces",
"/serverless-function/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import/serverless-function' /* webpackChunkName: 'dev-console/code-refs/serverlessFunction' */).then((m) => m.ServerlessFunctionPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/add",
"/import-sample",
"/catalog",
"/samples",
"/topology",
"/deploy-image",
"/project-details",
"/dev-monitoring",
"/upload-jar",
"/search-page",
"/builds",
"/functions"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components' /* webpackChunkName: 'dev-console/code-refs/common' */).then((m) => m.NamespaceRedirect))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/import"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components' /* webpackChunkName: 'dev-console/code-refs/common' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/add/all-namespaces",
"/add/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/add' /* webpackChunkName: 'dev-console/code-refs/add' */).then((m) => m.AddPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/topology/all-namespaces",
"/topology/ns/:name"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/topology' /* webpackChunkName: 'dev-console/code-refs/topology' */).then((m) => m.TopologyPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/catalog/all-namespaces",
"/catalog/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/catalog' /* webpackChunkName: 'dev-console/code-refs/catalog' */).then((m) => m.CatalogPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": "/edit/ns/:ns",
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/edit-application' /* webpackChunkName: 'dev-console/code-refs/editApplication' */).then((m) => m.EditApplication))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/k8s/ns/:ns/deployments/~new/form",
"/k8s/ns/:ns/deployments/:name/form"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/deployments' /* webpackChunkName: 'dev-console/code-refs/deployment' */).then((m) => m.DeploymentPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/k8s/ns/:ns/deploymentconfigs/~new/form",
"/k8s/ns/:ns/deploymentconfigs/:name/form"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/deployments' /* webpackChunkName: 'dev-console/code-refs/deployment' */).then((m) => m.DeploymentConfigPage))
},
"flags": {
"required": [
"OPENSHIFT_DEPLOYMENTCONFIG"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/import/all-namespaces",
"/import/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.ImportPage))
},
"flags": {
"required": [
"OPENSHIFT_BUILDCONFIG",
"OPENSHIFT_IMAGESTREAM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/import/all-namespaces",
"/import/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.ImportPage))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/samples/all-namespaces",
"/samples/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.SamplesCatalog))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/samples/all-namespaces/:is/:isNs",
"/samples/ns/:ns/:is/:isNs"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.ImportSamplePage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/catalog/source-to-image"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.ImportPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/k8s/ns/:ns/buildconfigs/~new/form",
"/k8s/ns/:ns/buildconfigs/:name/form"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/buildconfig' /* webpackChunkName: 'dev-console/code-refs/buildConfig' */).then((m) => m.BuildConfigFormPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/deploy-image/all-namespaces",
"/deploy-image/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.DeployImagePage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/upload-jar/all-namespaces",
"/upload-jar/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.UploadJarPage))
}
},
{
"type": "console.page/route",
"properties": {
"perspective": "dev",
"exact": false,
"path": [
"/project-details/all-namespaces",
"/project-details/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/projects' /* webpackChunkName: 'dev-console/code-refs/projects' */).then((m) => m.ProjectDetailsPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/dev-monitoring/all-namespaces",
"/dev-monitoring/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/monitoring' /* webpackChunkName: 'dev-console/code-refs/monitoring' */).then((m) => m.MonitoringPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/k8s/ns/:ns/:kind/:name/containers/:containerName/health-checks"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/health-checks' /* webpackChunkName: 'dev-console/code-refs/healthChecks' */).then((m) => m.HealthChecksPage))
}
},
{
"type": "console.page/route",
"properties": {
"perspective": "dev",
"exact": false,
"path": [
"/k8s/all-namespaces/import"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/import' /* webpackChunkName: 'dev-console/code-refs/import' */).then((m) => m.ImportYamlPage))
}
},
{
"type": "console.page/route",
"properties": {
"perspective": "dev",
"exact": false,
"path": [
"/k8s/all-namespaces/:plural"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components' /* webpackChunkName: 'dev-console/code-refs/common' */).then((m) => m.ProjectSelectPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/workload-hpa/ns/:ns/:resourceRef/:name"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components/hpa' /* webpackChunkName: 'dev-console/code-refs/hpa' */).then((m) => m.HPAPage))
}
},
{
"type": "console.page/route",
"properties": {
"perspective": "dev",
"exact": true,
"path": [
"/search-page/all-namespaces",
"/search-page/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/dev-console/src/components' /* webpackChunkName: 'dev-console/code-refs/common' */).then((m) => m.SearchPage))
}
}
],
],
});
activePlugins.push({
name: '@console/gitops-plugin',
extensions: [
...require('@console/gitops-plugin/src/plugin.ts').default,
...[
{
"type": "console.navigation/href",
"properties": {
"id": "environments",
"perspective": "dev",
"section": "resources",
"insertAfter": "pipelines",
"insertBefore": "helm",
"name": "%gitops-plugin~Environments%",
"href": "/environments",
"dataAttributes": {
"data-test-id": "environments-header"
}
},
"flags": {
"required": [
"OPENSHIFT_GITOPS"
],
"disallowed": [
"GITOPS_DYNAMIC"
]
}
}
],
],
});
activePlugins.push({
name: '@console/helm-plugin',
extensions: [
...require('@console/helm-plugin/src/plugin.ts').default,
...[
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "helm.openshift.io",
"version": "v1beta1",
"kind": "HelmChartRepository"
},
"label": "%helm-plugin~Helm Chart Repository%",
"labelPlural": "%helm-plugin~Helm Chart Repositories%",
"abbr": "HCR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "helm.openshift.io",
"version": "v1beta1",
"kind": "ProjectHelmChartRepository"
},
"label": "%helm-plugin~Project Helm Chart Repository%",
"labelPlural": "%helm-plugin~Project Helm Chart Repositories%",
"abbr": "PHCR"
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "helm.openshift.io",
"version": "v1beta1",
"kind": "HelmChartRepository"
},
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/actions' /* webpackChunkName: 'helm-plugin/code-refs/helmActions' */).then((m) => m.useHelmChartRepositoryActions))
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "helm.openshift.io",
"version": "v1beta1",
"kind": "ProjectHelmChartRepository"
},
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/actions' /* webpackChunkName: 'helm-plugin/code-refs/helmActions' */).then((m) => m.useHelmChartRepositoryActions))
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/helm-plugin/src/providers' /* webpackChunkName: 'helm-plugin/code-refs/helmProviders' */).then((m) => m.useDetectHelmChartRepositories))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/helm-releases",
"/helm",
"/helm-repositories"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/catalog/helm-install"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmInstallUpgradePage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/helm-releases/ns/:ns/:releaseName/upgrade"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmInstallUpgradePage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/helm-releases/ns/:ns/:releaseName/rollback"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmReleaseRollbackPage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/helm-releases/ns/:ns/release/:name"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmReleaseDetailsPage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/helm/all-namespaces",
"/helm/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmTabbedPage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/helm-releases/all-namespaces",
"/helm-releases/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.AdminHelmReleaseListPage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/helm-repositories/all-namespaces",
"/helm-repositories/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.AdminHelmRepositoriesListPage))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/helm-repositories/ns/:ns/~new/form",
"/helm-repositories/ns/:ns/:name/form",
"/k8s/cluster/helmchartrepositories/:name/form"
],
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmChartRepositoryForm))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "helm-chart-repositories",
"name": "%helm-plugin~Helm Chart repositories%",
"insertAfter": "local-machine"
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/helm-plugin/src/actions' /* webpackChunkName: 'helm-plugin/code-refs/helmActions' */).then((m) => m.helmChartTypeProvider))
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "helm",
"groupId": "developer-catalog",
"href": "/catalog/ns/:namespace?catalogType=HelmChart",
"label": "%helm-plugin~Helm Chart%",
"description": "%helm-plugin~Browse the catalog to discover and install Helm Charts%",
"icon": applyCodeRefSymbol(() => import('@console/helm-plugin/src/utils' /* webpackChunkName: 'helm-plugin/code-refs/helmUtils' */).then((m) => m.helmCatalogIconSVG))
},
"flags": {
"required": [
"OPENSHIFT_HELM",
"DEVELOPER_CATALOG",
"HELM_CHARTS_CATALOG_TYPE"
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "project-helm-chart-repositories",
"groupId": "helm-chart-repositories",
"href": "/helm-repositories/ns/:namespace/~new/form?actionOrigin=addPage",
"label": "%helm-plugin~Helm Chart repositories%",
"description": "%helm-plugin~Add a Helm Chart Repository to extend the Developer Catalog%",
"icon": applyCodeRefSymbol(() => import('@console/helm-plugin/src/utils' /* webpackChunkName: 'helm-plugin/code-refs/helmUtils' */).then((m) => m.helmChartRepositoriesIconElement)),
"accessReview": [
{
"group": "helm.openshift.io",
"resource": "ProjectHelmChartRepository",
"verb": "create"
}
]
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "HelmChart",
"title": "%helm-plugin~Helm Charts%",
"catalogDescription": applyCodeRefSymbol(() => import('@console/helm-plugin/src/catalog' /* webpackChunkName: 'helm-plugin/code-refs/helmCatalog' */).then((m) => m.getHelmCatalogTypeDescription)),
"typeDescription": "%helm-plugin~**Helm Charts** are packages for deploying an Application or components of a larger Application.%",
"filters": [
{
"label": "%helm-plugin~Chart Repositories%",
"attribute": "chartRepositoryTitle"
},
{
"label": "%helm-plugin~Source%",
"attribute": "providerType"
}
]
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "HelmChart",
"title": "%helm-plugin~Helm Charts%",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/catalog' /* webpackChunkName: 'helm-plugin/code-refs/helmCatalog' */).then((m) => m.helmChartProvider))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "helm-actions",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/actions' /* webpackChunkName: 'helm-plugin/code-refs/helmActions' */).then((m) => m.useHelmActionProvider))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/actions' /* webpackChunkName: 'helm-plugin/code-refs/helmActions' */).then((m) => m.useHelmActionProviderForTopology))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-context-actions",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/actions' /* webpackChunkName: 'helm-plugin/code-refs/helmActions' */).then((m) => m.useTopologyActionProvider))
},
"flags": {
"required": [
"OPENSHIFT_HELM",
"DEVELOPER_CATALOG",
"HELM_CHARTS_CATALOG_TYPE"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "helm",
"perspective": "dev",
"section": "resources",
"insertBefore": "project",
"name": "%helm-plugin~Helm%",
"href": "/helm",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-helm",
"data-test-id": "helm-releases-header"
}
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "helm-section",
"name": "%helm-plugin~Helm%",
"dataAttributes": {
"data-quickstart-id": "qs-admin-nav-helm"
},
"insertAfter": "operators"
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "repositories",
"perspective": "admin",
"section": "helm-section",
"name": "%helm-plugin~Repositories%",
"href": "/helm-repositories",
"namespaced": true,
"dataAttributes": {
"data-test-id": "helm-repositories-header"
}
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "releases",
"perspective": "admin",
"section": "helm-section",
"name": "%helm-plugin~Releases%",
"href": "/helm-releases",
"insertAfter": "repositories",
"namespaced": true,
"dataAttributes": {
"data-test-id": "helm-releases-header"
}
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.topology/component/factory",
"properties": {
"getFactory": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology' /* webpackChunkName: 'helm-plugin/code-refs/helmTopology' */).then((m) => m.getHelmComponentFactory))
}
},
{
"type": "console.topology/data/factory",
"properties": {
"id": "helm-topology-model-factory",
"priority": 400,
"resources": {
"secrets": {
"opts": {
"isList": true,
"kind": "Secret",
"optional": true,
"selector": {
"matchLabels": {
"owner": "helm"
},
"matchExpressions": [
{
"key": "status",
"operator": "NotEquals",
"values": [
"superseded"
]
}
]
},
"partialMetadata": true
}
}
},
"getDataModel": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology' /* webpackChunkName: 'helm-plugin/code-refs/helmTopology' */).then((m) => m.getHelmTopologyDataModel)),
"isResourceDepicted": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology' /* webpackChunkName: 'helm-plugin/code-refs/helmTopology' */).then((m) => m.isHelmResourceInModel))
}
},
{
"type": "console.topology/display/filters",
"properties": {
"getTopologyFilters": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology' /* webpackChunkName: 'helm-plugin/code-refs/helmTopology' */).then((m) => m.getTopologyFilters)),
"applyDisplayOptions": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology' /* webpackChunkName: 'helm-plugin/code-refs/helmTopology' */).then((m) => m.applyHelmDisplayOptions))
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 100,
"link": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology/sidebar' /* webpackChunkName: 'helm-plugin/code-refs/helmTopologySidebar' */).then((m) => m.getHelmReleasePanelResourceLink))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.topology/details/tab",
"properties": {
"id": "helm-release-panel-tab-releaseNotes",
"label": "%helm-plugin~Release notes%",
"insertAfter": "topology-side-bar-tab-resource"
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "helm-release-panel-tab-section-details",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology/sidebar' /* webpackChunkName: 'helm-plugin/code-refs/helmTopologySidebar' */).then((m) => m.useHelmReleasePanelDetailsTabSection))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "helm-release-panel-tab-section-resource",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology/sidebar' /* webpackChunkName: 'helm-plugin/code-refs/helmTopologySidebar' */).then((m) => m.useHelmReleasePanelResourceTabSection))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "helm-release-panel-tab-section-releaseNotes",
"tab": "helm-release-panel-tab-releaseNotes",
"provider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology/sidebar' /* webpackChunkName: 'helm-plugin/code-refs/helmTopologySidebar' */).then((m) => m.useHelmReleasePanelReleaseNotesTabSection))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "helm.openshift.io",
"version": "v1beta1",
"kind": "ProjectHelmChartRepository"
},
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.ProjectHelmChartRepositoryListPage))
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "helm.openshift.io",
"version": "v1beta1",
"kind": "HelmChartRepository"
},
"component": applyCodeRefSymbol(() => import('@console/helm-plugin/src/components' /* webpackChunkName: 'helm-plugin/code-refs/helmComponents' */).then((m) => m.HelmChartRepositoryListPage))
}
},
{
"type": "dev-console.detailsPage/breadcrumbs",
"properties": {
"getModels": applyCodeRefSymbol(() => import('@console/helm-plugin/src/providers' /* webpackChunkName: 'helm-plugin/code-refs/helmProviders' */).then((m) => m.getHelmChartRepositoriesModel)),
"breadcrumbsProvider": applyCodeRefSymbol(() => import('@console/helm-plugin/src/providers' /* webpackChunkName: 'helm-plugin/code-refs/helmProviders' */).then((m) => m.useHelmChartRepositoriesBreadcrumbs))
},
"flags": {
"required": [
"OPENSHIFT_HELM"
]
}
},
{
"type": "console.topology/decorator/provider",
"properties": {
"id": "helm-release-status-decorator",
"priority": 100,
"quadrant": "lowerLeft",
"decorator": applyCodeRefSymbol(() => import('@console/helm-plugin/src/topology' /* webpackChunkName: 'helm-plugin/code-refs/helmTopology' */).then((m) => m.getHelmReleaseStatusDecorator))
}
}
],
],
});
activePlugins.push({
name: '@console/insights-plugin',
extensions: [
...require('@console/insights-plugin/src/plugin.tsx').default,
...[],
],
});
activePlugins.push({
name: '@console/knative-plugin',
extensions: [
...require('@console/knative-plugin/src/plugin.tsx').default,
...[
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "operator.knative.dev",
"version": "v1alpha1",
"kind": "KnativeServing"
},
"flag": "KNATIVE_SERVING"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "operator.knative.dev",
"version": "v1alpha1",
"kind": "KnativeEventing"
},
"flag": "KNATIVE_EVENTING"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "operator.knative.dev",
"version": "v1beta1",
"kind": "KnativeServing"
},
"flag": "KNATIVE_SERVING"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "operator.knative.dev",
"version": "v1beta1",
"kind": "KnativeEventing"
},
"flag": "KNATIVE_EVENTING"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Configuration"
},
"flag": "KNATIVE_SERVING_CONFIGURATION"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Revision"
},
"flag": "KNATIVE_SERVING_REVISION"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Route"
},
"flag": "KNATIVE_SERVING_ROUTE"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"flag": "KNATIVE_SERVING_SERVICE"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Broker"
},
"flag": "FLAG_KNATIVE_EVENTING_BROKER"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Channel"
},
"flag": "FLAG_KNATIVE_EVENTING_CHANNEL"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Trigger"
},
"flag": "FLAG_KNATIVE_EVENTING_TRIGGER"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Subscription"
},
"flag": "FLAG_KNATIVE_EVENTING_SUBSCRIPTION"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "camel.apache.org",
"version": "v1alpha1",
"kind": "Kamelet"
},
"flag": "FLAG_CAMEL_KAMELETS"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1alpha1",
"kind": "KafkaSink"
},
"flag": "FLAG_KAFKA_SINK"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1",
"kind": "PingSource"
},
"flag": "FLAG_EVENT_SOURCE_PING"
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Revision"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/revisions' /* webpackChunkName: 'knative-plugin/code-refs/revisionsComponent' */).then((m) => m.RevisionsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/services' /* webpackChunkName: 'knative-plugin/code-refs/servicesComponent' */).then((m) => m.ServicesPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Route"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/routes' /* webpackChunkName: 'knative-plugin/code-refs/routesComponent' */).then((m) => m.RoutesPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_ROUTE"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Revision"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/revisions' /* webpackChunkName: 'knative-plugin/code-refs/revisionsComponent' */).then((m) => m.RevisionDetailsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Route"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/routes' /* webpackChunkName: 'knative-plugin/code-refs/routesComponent' */).then((m) => m.RouteDetailsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_ROUTE"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/services' /* webpackChunkName: 'knative-plugin/code-refs/servicesComponent' */).then((m) => m.ServiceDetailsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "operator.knative.dev",
"version": "v1alpha1",
"kind": "KnativeServing"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/overview' /* webpackChunkName: 'knative-plugin/code-refs/overviewComponent' */).then((m) => m.KnativeServingDetailsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Trigger"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.TriggerListPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_TRIGGER"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Trigger"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/pub-sub' /* webpackChunkName: 'knative-plugin/code-refs/pubSubComponent' */).then((m) => m.TriggerDetailsPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_TRIGGER"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Subscription"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.SubscriptionListPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_SUBSCRIPTION"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Subscription"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/pub-sub' /* webpackChunkName: 'knative-plugin/code-refs/pubSubComponent' */).then((m) => m.SubscriptionDetailsPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_SUBSCRIPTION"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/event-source"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components' /* webpackChunkName: 'knative-plugin/code-refs/knativeComponent' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/catalog/all-namespaces/eventsource",
"/catalog/ns/:ns/eventsource"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/add' /* webpackChunkName: 'knative-plugin/code-refs/addComponent' */).then((m) => m.EventSourcePage))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/catalog/ns/:ns/eventsink"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/add' /* webpackChunkName: 'knative-plugin/code-refs/addComponent' */).then((m) => m.EventSinkPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/channel"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components' /* webpackChunkName: 'knative-plugin/code-refs/knativeComponent' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_CHANNEL"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/channel/all-namespaces",
"/channel/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/add' /* webpackChunkName: 'knative-plugin/code-refs/addComponent' */).then((m) => m.EventingChannelPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_CHANNEL"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/broker/all-namespaces",
"/broker/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/add' /* webpackChunkName: 'knative-plugin/code-refs/addComponent' */).then((m) => m.EventingBrokerPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_BROKER"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/serving"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components' /* webpackChunkName: 'knative-plugin/code-refs/knativeComponent' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"KNATIVE_SERVING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/functions/all-namespaces",
"/functions/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/overview' /* webpackChunkName: 'knative-plugin/code-refs/overviewComponent' */).then((m) => m.FunctionsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/functions"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components' /* webpackChunkName: 'knative-plugin/code-refs/knativeComponent' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"KNATIVE_SERVING"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "functions",
"perspective": "dev",
"section": "resources",
"name": "%knative-plugin~Functions%",
"href": "/functions",
"namespaced": true,
"insertBefore": "builds",
"dataAttributes": {
"data-quickstart-id": "qs-nav-functions",
"data-test-id": "functions-details-header"
}
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/functions/ns/:ns/:name"
],
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/overview' /* webpackChunkName: 'knative-plugin/code-refs/overviewComponent' */).then((m) => m.FunctionDetailsPage))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "EventType",
"title": "%knative-plugin~Event Types%",
"catalogDescription": "%knative-plugin~​​Event Types available that can be consumed with Serverless Functions or regular Deployments.%",
"typeDescription": "%knative-plugin~​​**Event Types** available that can be consumed with Serverless Functions or regular Deployments.%",
"filters": [
{
"label": "%knative-plugin~Provider%",
"attribute": "provider"
}
]
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "EventType",
"title": "%knative-plugin~Event Types%",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.eventTypeProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/serving/all-namespaces",
"/serving/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/overview' /* webpackChunkName: 'knative-plugin/code-refs/overviewComponent' */).then((m) => m.ServingListPage))
},
"flags": {
"required": [
"KNATIVE_SERVING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/eventing"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components' /* webpackChunkName: 'knative-plugin/code-refs/knativeComponent' */).then((m) => m.NamespaceRedirect))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/eventing/all-namespaces",
"/eventing/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.EventingListPage))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/knatify/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/knatify' /* webpackChunkName: 'knative-plugin/code-refs/knatifyComponent' */).then((m) => m.CreateKnatifyPage))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.useKnativeEventingProvider))
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.knativeEventingTypeProvider))
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"KNATIVE_EVENTING",
"DEVELOPER_CATALOG",
"FLAG_KNATIVE_EVENTING_ENABLED"
]
},
"properties": {
"id": "knative-event-type",
"groupId": "eventing",
"href": "/catalog/ns/:namespace?catalogType=EventType",
"label": "%knative-plugin~Event Type%",
"description": "%knative-plugin~​​Event Types available that can be consumed with Serverless Functions or regular Deployments%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.eventSourceIconSVG))
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"KNATIVE_EVENTING",
"DEVELOPER_CATALOG",
"KNATIVE_EVENT_SOURCE_CATALOG_TYPE",
"FLAG_KNATIVE_EVENTING_ENABLED"
]
},
"properties": {
"id": "knative-event-source",
"groupId": "eventing",
"href": "/catalog/ns/:namespace?catalogType=EventSource&provider=[\"Red+Hat\"]",
"label": "%knative-plugin~Event Source%",
"description": "%knative-plugin~Create an Event source to register interest in a class of events from a particular system%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.eventSourceIconSVG))
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"KNATIVE_EVENTING",
"DEVELOPER_CATALOG",
"KNATIVE_EVENT_SINK_CATALOG_TYPE",
"FLAG_KNATIVE_EVENTING_ENABLED"
]
},
"properties": {
"id": "knative-event-sink",
"groupId": "eventing",
"href": "/catalog/ns/:namespace?catalogType=EventSink",
"label": "%knative-plugin~Event Sink%",
"description": "%knative-plugin~Create an Event sink to receive incoming events from a particular source%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.eventSinkIconSVG))
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
},
"properties": {
"id": "create-serverless-function",
"groupId": "serverless-function",
"href": "/serverless-function/ns/:namespace",
"label": "%knative-plugin~Import from Git%",
"description": "%knative-plugin~Create and deploy stateless, Serverless functions%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.gitIconElement)),
"accessReview": [
{
"group": "serving.knative.dev",
"resource": "services",
"verb": "create"
}
]
}
},
{
"type": "dev-console.add/action",
"properties": {
"id": "create-serverless-function-samples",
"groupId": "serverless-function",
"href": "/samples/ns/:namespace?sampleType=Serverless function",
"label": "%knative-plugin~Samples%",
"description": "%knative-plugin~Build from pre-built function sample code%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.samplesIconElement)),
"accessReview": [
{
"group": "build.openshift.io",
"resource": "buildconfigs",
"verb": "create"
},
{
"group": "image.openshift.io",
"resource": "imagestreams",
"verb": "create"
},
{
"group": "apps.openshift.io",
"resource": "deploymentconfigs",
"verb": "create"
},
{
"group": "",
"resource": "secrets",
"verb": "create"
},
{
"group": "route.openshift.io",
"resource": "routes",
"verb": "create"
},
{
"group": "",
"resource": "services",
"verb": "create"
},
{
"group": "serving.knative.dev",
"resource": "services",
"verb": "create"
}
]
},
"flags": {
"required": [
"DEVELOPER_CATALOG",
"SAMPLE_CATALOG_TYPE",
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_BROKER"
]
},
"properties": {
"id": "knative-eventing-broker",
"groupId": "eventing",
"href": "/broker/ns/:namespace",
"label": "%knative-plugin~Broker%",
"description": "%knative-plugin~Create a Broker to define an event mesh for collecting a pool of events and route those events based on attributes, through triggers%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.channelIconSVG)),
"accessReview": [
{
"group": "eventing.knative.dev",
"resource": "brokers",
"verb": "create"
}
]
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_CHANNEL"
]
},
"properties": {
"id": "knative-eventing-channel",
"groupId": "eventing",
"href": "/channel/ns/:namespace",
"label": "%knative-plugin~Channel%",
"description": "%knative-plugin~Create a Knative Channel to create an event forwarding and persistence layer with in-memory and reliable implementations%",
"icon": applyCodeRefSymbol(() => import('@console/knative-plugin/src/utils/icons.tsx' /* webpackChunkName: 'knative-plugin/code-refs/icons' */).then((m) => m.channelIconSVG)),
"accessReview": [
{
"group": "eventing.knative.dev",
"resource": "channels",
"verb": "create"
}
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "EventSource",
"title": "%knative-plugin~Event Sources%",
"catalogDescription": "%knative-plugin~Event sources are objects that link to an event producer and an event sink or consumer. Cluster administrators can customize the content made available in the catalog.%",
"typeDescription": "%knative-plugin~**Event sources** are objects that link to an event producer and an event sink or consumer.%",
"filters": [
{
"label": "%knative-plugin~Provider%",
"attribute": "provider"
}
]
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "EventSource",
"title": "%knative-plugin~Event Sources%",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.useEventSourceProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "EventSource",
"title": "%knative-plugin~Event Sources%",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.kameletsProvider))
},
"flags": {
"required": [
"FLAG_CAMEL_KAMELETS"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "EventSink",
"title": "%knative-plugin~Event Sinks%",
"catalogDescription": "%knative-plugin~​​Event sinks are resources that can receive incoming events from other resources. Cluster administrators can customize the content made available in the catalog.%",
"typeDescription": "%knative-plugin~​​**Event sinks** are resources that can receive incoming events from other resources.%",
"filters": [
{
"label": "%knative-plugin~Provider%",
"attribute": "provider"
}
]
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "EventSink",
"title": "%knative-plugin~Event Sinks%",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.kameletsSinkProvider))
},
"flags": {
"required": [
"FLAG_CAMEL_KAMELETS"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "EventSink",
"title": "%knative-plugin~Event Sinks%",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/catalog' /* webpackChunkName: 'knative-plugin/code-refs/catalog' */).then((m) => m.kafkaSinkProvider))
},
"flags": {
"required": [
"FLAG_KAFKA_SINK"
]
}
},
{
"type": "console.global-config",
"properties": {
"id": "knative-serving",
"name": "knative-serving",
"model": {
"group": "operator.knative.dev",
"version": "v1beta1",
"kind": "KnativeServing"
},
"namespace": "knative-serving"
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "serverlessserving",
"perspective": "admin",
"section": "serverless",
"name": "%knative-plugin~Serving%",
"href": "/serving",
"namespaced": true
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_ROUTE",
"KNATIVE_SERVING_REVISION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "serverlesseventing",
"perspective": "admin",
"section": "serverless",
"name": "%knative-plugin~Eventing%",
"href": "/eventing",
"namespaced": true
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_CHANNEL",
"FLAG_KNATIVE_EVENTING_BROKER"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "serverlessfunctions",
"perspective": "admin",
"section": "serverless",
"name": "%knative-plugin~Functions%",
"href": "/functions",
"namespaced": true,
"insertAfter": "serverlesseventing",
"dataAttributes": {
"data-test-id": "functions-details-header"
}
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.yaml-template",
"properties": {
"name": "default",
"model": {
"group": "serving.knative.dev",
"kind": "Service",
"version": "v1"
},
"template": applyCodeRefSymbol(() => import('@console/knative-plugin/src/yaml-templates.ts' /* webpackChunkName: 'knative-plugin/code-refs/yamlTemplates' */).then((m) => m.defaultYamlTemplate))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps",
"version": "v1",
"kind": "Deployment"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useMakeServerlessActionProvider))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "apps.openshift.io",
"version": "v1",
"kind": "DeploymentConfig"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useMakeServerlessActionProvider))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Subscription"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useSinkPubSubActionProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_SUBSCRIPTION"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Trigger"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useSinkPubSubActionProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"FLAG_KNATIVE_EVENTING_TRIGGER"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Broker"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useBrokerActionProvider))
},
"flags": {
"required": [
"FLAG_KNATIVE_EVENTING_BROKER"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Channel"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useChannelActionProvider))
},
"flags": {
"required": [
"FLAG_KNATIVE_EVENTING_CHANNEL"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "InMemoryChannel"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useChannelActionProvider))
},
"flags": {
"required": [
"FLAG_KNATIVE_EVENTING_CHANNEL"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "knative-plugin-tab-section-resource-pubsub",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useResourceTabPubSubSectionForTopologySidebar))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/relationship/provider",
"properties": {
"provides": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.providerProvidesServiceBinding)),
"tooltip": "%knative-plugin~Create Service Binding%",
"create": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.providerCreateServiceBinding)),
"priority": 200
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.topology/component/factory",
"properties": {
"getFactory": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getKnativeServingComponentFactory))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/component/factory",
"properties": {
"getFactory": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getKnativeEventingComponentFactory))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/display/filters",
"properties": {
"getTopologyFilters": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getTopologyFilters)),
"applyDisplayOptions": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.applyKnativeDisplayOptions))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/create/connector",
"properties": {
"getCreateConnector": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getCreateConnector))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/decorator/provider",
"properties": {
"id": "knative-service-route-decorator",
"priority": 100,
"quadrant": "upperRight",
"decorator": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getServiceRouteDecorator))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/decorator/provider",
"properties": {
"id": "revision-url-decorator",
"priority": 100,
"quadrant": "upperRight",
"decorator": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getRevisionRouteDecorator))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Channel"
},
"page": {
"name": "%knative-plugin~Subscriptions%",
"href": "subscriptions"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.ChannelSubscriptionTab))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Broker"
},
"page": {
"name": "%knative-plugin~Triggers%",
"href": "triggers"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.BrokerTriggerTab))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1beta1",
"kind": "KafkaChannel"
},
"page": {
"name": "%knative-plugin~Subscriptions%",
"href": "subscriptions"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.ChannelSubscriptionTab))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "InMemoryChannel"
},
"page": {
"name": "%knative-plugin~Subscriptions%",
"href": "subscriptions"
},
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/eventing' /* webpackChunkName: 'knative-plugin/code-refs/eventingComponent' */).then((m) => m.ChannelSubscriptionTab))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-context-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useTopologyActionsProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/filter",
"properties": {
"contextId": "topology-context-actions",
"filter": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.topologyServerlessActionsFilter))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useModifyApplicationActionProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING",
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-ksvc-details",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelDetailsTab))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/adapter/pod",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.getKnativeSidepanelPodsAdapterSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-ksvc-revision",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-pods-overview",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelRevisionSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-knative-routes",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-revision-deployment",
"insertBefore": "topology-tab-section-builds-overview",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelRoutesSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/adapter/build",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.getKnativeSidepanelBuildAdapterSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-eventsources",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-builds-overview",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelEventSourcesSection))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-ksvc-subscriptions",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-eventsources",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelSubscriptionsSection))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-ksvc-triggers",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-ksvc-subscriptions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelTriggersSection))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-ksvc-domain-mappings",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-ksvc-triggers",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelDomainMappingsSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useKnativeServiceActionsProvider))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Revision"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useCommonActionsProvider))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Route"
},
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useCommonActionsProvider))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-revision-deployment",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-pods-overview",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelDeploymentSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-revision-configuration",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-knative-routes",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelConfigurationsSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useUriActionsProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useKnativeEventSinkActionProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useEventSourcesActionsProviderForTopology))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "event-source-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useEventSourcesActionsProvider))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-eventsource-target",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelSinkSection))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-eventsource-associateddeployment",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelSinkAssociatedDeployment))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-eventsource-ownedeventsources",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelSinkEventSources))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 100,
"link": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.getKnativeURISinkResourceLink))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/actions/providers.ts' /* webpackChunkName: 'knative-plugin/code-refs/actions' */).then((m) => m.useKnativeConnectorActionProvider))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-kn-connector-resource",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeConnectorSidepanelResourceSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev"
},
"color": "#7d1007"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "serving.knative.dev"
},
"color": "#005f60"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "camel.apache.org"
},
"color": "#7d1007"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "eventing.knative.dev"
},
"color": "#7d1007"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "kafka.strimzi.io"
},
"color": "#7d1007"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "messaging.knative.dev"
},
"color": "#7d1007"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Configuration"
},
"label": "%knative-plugin~Configuration%",
"labelPlural": "%knative-plugin~Configurations%",
"abbr": "CFG"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "operator.knative.dev",
"kind": "KnativeServing"
},
"color": "#005f60",
"label": "%knative-plugin~KnativeServing%",
"labelPlural": "%knative-plugin~KnativeServings%",
"abbr": "KS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "operator.knative.dev",
"kind": "KnativeEventing"
},
"color": "#7d1007",
"label": "%knative-plugin~KnativeEventing%",
"labelPlural": "%knative-plugin~KnativeEventings%",
"abbr": "KE"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Revision"
},
"label": "%knative-plugin~Revision%",
"labelPlural": "%knative-plugin~Revisions%",
"abbr": "REV"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Route"
},
"label": "%knative-plugin~Route%",
"labelPlural": "%knative-plugin~Routes%",
"abbr": "RT"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1",
"kind": "Service"
},
"label": "%knative-plugin~Service%",
"labelPlural": "%knative-plugin~Services%",
"abbr": "KSVC"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "serving.knative.dev",
"version": "v1beta1",
"kind": "DomainMapping"
},
"label": "%knative-plugin~DomainMapping%",
"labelPlural": "%knative-plugin~DomainMappings%",
"abbr": "DM"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1",
"kind": "PingSource"
},
"label": "%knative-plugin~PingSource%",
"labelPlural": "%knative-plugin~PingSources%",
"abbr": "PS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1",
"kind": "ContainerSource"
},
"label": "%knative-plugin~ContainerSource%",
"labelPlural": "%knative-plugin~ContainerSources%",
"abbr": "CS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1",
"kind": "ApiServerSource"
},
"label": "%knative-plugin~ApiServerSource%",
"labelPlural": "%knative-plugin~ApiServerSources%",
"abbr": "AS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1alpha1",
"kind": "CamelSource"
},
"label": "%knative-plugin~CamelSource%",
"labelPlural": "%knative-plugin~CamelSources%",
"abbr": "CS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1beta1",
"kind": "KafkaSource"
},
"label": "%knative-plugin~KafkaSource%",
"labelPlural": "%knative-plugin~KafkaSources%",
"abbr": "KS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "sources.knative.dev",
"version": "v1",
"kind": "SinkBinding"
},
"label": "%knative-plugin~SinkBinding%",
"labelPlural": "%knative-plugin~SinkBindings%",
"abbr": "SBS"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Subscription"
},
"label": "%knative-plugin~Subscription%",
"labelPlural": "%knative-plugin~Subscriptions%",
"abbr": "S"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "InMemoryChannel"
},
"label": "%knative-plugin~InMemoryChannel%",
"labelPlural": "%knative-plugin~InMemoryChannels%",
"abbr": "IMC"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1",
"kind": "Channel"
},
"label": "%knative-plugin~Channel%",
"labelPlural": "%knative-plugin~Channels%",
"abbr": "C"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "messaging.knative.dev",
"version": "v1beta1",
"kind": "KafkaChannel"
},
"label": "%knative-plugin~KafkaChannel%",
"labelPlural": "%knative-plugin~KafkaChannels%",
"abbr": "KC"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Broker"
},
"label": "%knative-plugin~Broker%",
"labelPlural": "%knative-plugin~Brokers%",
"abbr": "B"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1",
"kind": "Trigger"
},
"label": "%knative-plugin~Trigger%",
"labelPlural": "%knative-plugin~Triggers%",
"abbr": "T"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "camel.apache.org",
"version": "v1",
"kind": "Integration"
},
"label": "%knative-plugin~Integration%",
"labelPlural": "%knative-plugin~Integrations%",
"abbr": "I"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "kafka.strimzi.io",
"version": "v1beta2",
"kind": "Kafka"
},
"label": "%knative-plugin~Kafka%",
"labelPlural": "%knative-plugin~Kafkas%",
"abbr": "K"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "kafka.strimzi.io",
"version": "v1beta2",
"kind": "KafkaTopic"
},
"label": "%knative-plugin~KafkaTopic%",
"labelPlural": "%knative-plugin~KafkaTopics%",
"abbr": "KT"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "camel.apache.org",
"version": "v1alpha1",
"kind": "KameletBinding"
},
"label": "%knative-plugin~KameletBinding%",
"labelPlural": "%knative-plugin~KameletBindings%",
"abbr": "KB"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "camel.apache.org",
"version": "v1alpha1",
"kind": "Kamelet"
},
"label": "%knative-plugin~Kamelet%",
"labelPlural": "%knative-plugin~Kamelets%",
"abbr": "K"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "camel.apache.org",
"version": "v1",
"kind": "IntegrationPlatform"
},
"label": "%knative-plugin~IntegrationPlatform%",
"labelPlural": "%knative-plugin~IntegrationPlatforms%",
"abbr": "IP"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "eventing.knative.dev",
"version": "v1alpha1",
"kind": "KafkaSink"
},
"label": "%knative-plugin~KafkaSink%",
"labelPlural": "%knative-plugin~KafkaSinks%",
"abbr": "KS"
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/eventing-context.ts' /* webpackChunkName: 'knative-plugin/code-refs/eventingContext' */).then((m) => m.EventingContextProvider)),
"useValueHook": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/eventing-context.ts' /* webpackChunkName: 'knative-plugin/code-refs/eventingContext' */).then((m) => m.useValuesEventingContext))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-kn-event-sink-details",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidePanelEventSinkDetailsTab))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-kn-event-sink-output-target",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.useKnativeSidepanelEventSinkSection))
},
"flags": {
"required": [
"KNATIVE_SERVING_SERVICE",
"KNATIVE_SERVING_REVISION",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/adapter/pod",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.getEventSinkPodsApdapter))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.topology/data/factory",
"properties": {
"id": "knative-kafka-sink-topology-data-factory",
"priority": "500",
"resources": {
"kafkasinks": {
"model": {
"group": "eventing.knative.dev",
"version": "v1alpha1",
"kind": "KafkaSink"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
}
},
"workloadKeys": [
"kafkasinks"
],
"getDataModel": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getKafkaSinkKnativeTopologyData))
},
"flags": {
"required": [
"FLAG_KAFKA_SINK"
]
}
},
{
"type": "console.topology/component/factory",
"properties": {
"getFactory": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology' /* webpackChunkName: 'knative-plugin/code-refs/topology' */).then((m) => m.getKafkaSinkComponentFactory))
},
"flags": {
"required": [
"FLAG_KAFKA_SINK"
]
}
},
{
"type": "console.topology/adapter/pod",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/knative-plugin/src/topology/sidebar' /* webpackChunkName: 'knative-plugin/code-refs/knativeTopologySidebar' */).then((m) => m.getEventSourcePodsApdapter))
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "serverless",
"name": "%knative-plugin~Serverless%",
"dataAttributes": {
"data-quickstart-id": "qs-nav-serverless"
},
"insertBefore": "networking",
"insertAfter": "virtualization"
},
"flags": {
"required": [
"KNATIVE_SERVING",
"KNATIVE_EVENTING"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/catalog/ns/:ns/subscribe"
],
"component": applyCodeRefSymbol(() => import('@console/knative-plugin/src/components/add' /* webpackChunkName: 'knative-plugin/code-refs/addComponent' */).then((m) => m.SubscribePage))
},
"flags": {
"required": [
"KNATIVE_EVENTING"
]
}
}
],
],
});
activePlugins.push({
name: '@console/local-storage-operator-plugin',
extensions: [
...require('@console/local-storage-operator-plugin/src/plugin.ts').default,
...[],
],
});
activePlugins.push({
name: '@console/metal3-plugin',
extensions: [
...require('@console/metal3-plugin/src/plugin.tsx').default,
...[
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/metal3-plugin/src/features.ts' /* webpackChunkName: 'metal3-plugin/code-refs/features' */).then((m) => m.useDetectNodeMaintenance))
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"id": "baremetal",
"section": "compute",
"name": "%metal3-plugin~Bare Metal Hosts%",
"insertBefore": "computeseparator",
"model": {
"kind": "BareMetalHost",
"version": "v1alpha1",
"group": "metal3.io"
}
},
"flags": {
"required": [
"BAREMETAL",
"METAL3"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"version": "v1",
"kind": "Node"
},
"provider": applyCodeRefSymbol(() => import('@console/metal3-plugin/src/components/maintenance/actions.tsx' /* webpackChunkName: 'metal3-plugin/code-refs/nodeActions' */).then((m) => m.useNodeMaintenanceActions))
},
"flags": {
"required": [
"NODE_MAINTENANCE_ENABLED"
]
}
}
],
],
});
activePlugins.push({
name: '@console/network-attachment-definition-plugin',
extensions: [
...require('@console/network-attachment-definition-plugin/src/plugin.ts').default,
...[],
],
});
activePlugins.push({
name: '@console/operator-lifecycle-manager-v1',
extensions: [
...require('@console/operator-lifecycle-manager-v1/src/plugin.tsx').default,
...[
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "catalogd.operatorframework.io",
"version": "v1alpha1",
"kind": "ClusterCatalog"
},
"flag": "CLUSTER_CATALOG_API"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "olm.operatorframework.io",
"version": "v1alpha1",
"kind": "ClusterExtension"
},
"flag": "CLUSTER_EXTENSION_API"
}
},
{
"type": "console.navigation/section",
"properties": {
"id": "ecosystem",
"perspective": "admin",
"name": "%olm-v1~Ecosystem%",
"insertAfter": "usermanagement"
},
"flags": {
"required": [
"CLUSTER_CATALOG_API",
"FALSE"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "extension-catalog",
"perspective": "admin",
"section": "ecosystem",
"name": "%olm-v1~Extension Catalog%",
"href": "/ecosystem/catalog"
},
"flags": {
"required": [
"CLUSTER_CATALOG_API",
"FALSE"
]
}
},
{
"type": "console.page/route",
"properties": {
"path": "/ecosystem/catalog",
"component": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager-v1/src/components/ExtensionCatalog.tsx' /* webpackChunkName: 'operator-lifecycle-manager-v1/code-refs/ExtensionCatalog' */).then((m) => m.default))
},
"flags": {
"required": [
"CLUSTER_EXTENSION_API"
]
}
},
{
"type": "console.navigation/resource-cluster",
"properties": {
"id": "installed-extensions",
"section": "ecosystem",
"name": "%olm-v1~Installed Extensions%",
"model": {
"kind": "ClusterExtension",
"version": "v1alpha1",
"group": "olm.operatorframework.io"
},
"startsWith": [
"olm.operatorframework.io"
]
},
"flags": {
"required": [
"CLUSTER_EXTENSION_API"
]
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager-v1/src/contexts/ExtensionCatalogDatabaseContext.ts' /* webpackChunkName: 'operator-lifecycle-manager-v1/code-refs/ExtensionCatalogDatabaseContextProvider' */).then((m) => m.default)),
"useValueHook": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager-v1/src/contexts/useExtensionCatalogDatabaseContextValues.ts' /* webpackChunkName: 'operator-lifecycle-manager-v1/code-refs/useExtensionCatalogDatabaseContextValues' */).then((m) => m.default))
},
"flags": {
"required": [
"CLUSTER_CATALOG_API"
]
}
}
],
],
});
activePlugins.push({
name: '@console/operator-lifecycle-manager',
extensions: [
...require('@console/operator-lifecycle-manager/src/plugin.tsx').default,
...[
{
"type": "console.catalog/item-type",
"properties": {
"type": "OperatorBackedService",
"title": "%olm~Operator Backed%",
"catalogDescription": "%olm~Browse for a variety of managed services that are installed by cluster administrators. Cluster administrators can customize the content made available in the catalog.%",
"typeDescription": "%olm~**Operator backed** includes a variety of services managed by Kubernetes controllers.%",
"groupings": [
{
"label": "Operators",
"attribute": "operatorName"
}
]
},
"flags": {
"required": [
"OPERATOR_LIFECYCLE_MANAGER"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "dev-catalog",
"type": "OperatorBackedService",
"title": "%olm~Operator Backed Services%",
"provider": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/utils/index.ts' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/utils' */).then((m) => m.catalogCSVProvider))
},
"flags": {
"required": [
"OPERATOR_LIFECYCLE_MANAGER"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "operatorhub",
"section": "operators",
"name": "%olm~OperatorHub%",
"href": "/operatorhub"
},
"flags": {
"required": [
"CAN_LIST_PACKAGE_MANIFEST",
"CAN_LIST_OPERATOR_GROUP"
]
}
},
{
"type": "console.navigation/resource-ns",
"properties": {
"id": "operators",
"section": "operators",
"name": "%olm~Installed Operators%",
"model": {
"kind": "ClusterServiceVersion",
"version": "v1alpha1",
"group": "operators.coreos.com"
},
"startsWith": [
"operators.coreos.com",
"clusterserviceversions"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-operator-link",
"tab": "topology-side-bar-tab-resource",
"insertBefore": "topology-tab-section-pods-overview",
"provider": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/components/topology' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/topology' */).then((m) => m.useManagedByOperatorLinkSideBarTabSection))
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 100,
"link": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/components/topology/sidebar' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/topologySidebar' */).then((m) => m.getOperatorBackedPanelResourceLink))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "operator-backed-panel-tab-section-details",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/components/topology/sidebar' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/topologySidebar' */).then((m) => m.useOperatorBackedPanelDetailsSection))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "operator-backed-panel-tab-section-resource",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/components/topology/sidebar' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/topologySidebar' */).then((m) => m.useOperatorBackedPanelResourceSection))
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "csv-actions",
"provider": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/actions' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/actions' */).then((m) => m.useDefaultCSVActions))
}
},
{
"type": "console.page/route",
"properties": {
"path": "/operatorhub/install/:catalogNamespace/:catalog/:pkg/:currentCSV/to/:targetNamespace/",
"component": applyCodeRefSymbol(() => import('@console/operator-lifecycle-manager/src/components/operator-install-page.tsx' /* webpackChunkName: 'operator-lifecycle-manager/code-refs/install' */).then((m) => m.OperatorInstallStatusPage))
}
}
],
],
});
activePlugins.push({
name: '@console/pipelines-plugin',
extensions: [
...require('@console/pipelines-plugin/src/plugin.tsx').default,
...[
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.useIsPipelineOperatorVersion_1_17_or_newer))
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Pipeline"
},
"color": "#38812f",
"label": "%pipelines-plugin~Pipeline%",
"labelPlural": "%pipelines-plugin~Pipelines%",
"abbr": "PL"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Pipeline"
},
"color": "#38812f",
"label": "%pipelines-plugin~Pipeline%",
"labelPlural": "%pipelines-plugin~Pipelines%",
"abbr": "PL"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "PipelineRun"
},
"color": "#38812f",
"label": "%pipelines-plugin~PipelineRun%",
"labelPlural": "%pipelines-plugin~PipelineRuns%",
"abbr": "PLR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "PipelineRun"
},
"color": "#38812f",
"label": "%pipelines-plugin~PipelineRun%",
"labelPlural": "%pipelines-plugin~PipelineRuns%",
"abbr": "PLR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Task"
},
"color": "#38812f",
"label": "%pipelines-plugin~Task%",
"labelPlural": "%pipelines-plugin~Tasks%",
"abbr": "T"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Task"
},
"color": "#38812f",
"label": "%pipelines-plugin~Task%",
"labelPlural": "%pipelines-plugin~Tasks%",
"abbr": "T"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "TaskRun"
},
"color": "#38812f",
"label": "%pipelines-plugin~TaskRun%",
"labelPlural": "%pipelines-plugin~TaskRuns%",
"abbr": "TR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "TaskRun"
},
"color": "#38812f",
"label": "%pipelines-plugin~TaskRun%",
"labelPlural": "%pipelines-plugin~TaskRuns%",
"abbr": "TR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1alpha1",
"kind": "PipelineResource"
},
"color": "#38812f",
"label": "%pipelines-plugin~PipelineResource%",
"labelPlural": "%pipelines-plugin~PipelineResources%",
"abbr": "PR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "ClusterTask"
},
"color": "#38812f",
"label": "%pipelines-plugin~ClusterTask%",
"labelPlural": "%pipelines-plugin~ClusterTasks%",
"abbr": "CT"
},
"flags": {
"disallowed": [
"PIPELINES_OPERATOR_VERSION_1_17_OR_NEWER"
]
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1alpha1",
"kind": "Condition"
},
"color": "#38812f",
"label": "%pipelines-plugin~Condition%",
"labelPlural": "%pipelines-plugin~Conditions%",
"abbr": "C"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "TriggerBinding"
},
"color": "#38812f",
"label": "%pipelines-plugin~TriggerBinding%",
"labelPlural": "%pipelines-plugin~TriggerBindings%",
"abbr": "TB",
"badge": "tech"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "ClusterTriggerBinding"
},
"color": "#38812f",
"label": "%pipelines-plugin~ClusterTriggerBinding%",
"labelPlural": "%pipelines-plugin~ClusterTriggerBindings%",
"abbr": "CTB",
"badge": "tech"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "TriggerTemplate"
},
"color": "#38812f",
"label": "%pipelines-plugin~TriggerTemplate%",
"labelPlural": "%pipelines-plugin~TriggerTemplates%",
"abbr": "TT",
"badge": "tech"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "EventListener"
},
"color": "#38812f",
"label": "%pipelines-plugin~EventListener%",
"labelPlural": "%pipelines-plugin~EventListeners%",
"abbr": "EL",
"badge": "tech"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "pipelinesascode.tekton.dev",
"version": "v1alpha1",
"kind": "Repository"
},
"color": "#38812f",
"label": "%pipelines-plugin~Repository%",
"labelPlural": "%pipelines-plugin~Repositories%",
"abbr": "R",
"badge": "dev"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "operator.tekton.dev",
"version": "v1alpha1",
"kind": "TektonConfig"
},
"color": "#38812f",
"label": "%pipelines-plugin~TektonConfig%",
"labelPlural": "%pipelines-plugin~TektonConfigs%",
"abbr": "TC"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "operator.tekton.dev",
"version": "v1alpha1",
"kind": "TektonHub"
},
"color": "#38812f",
"label": "%pipelines-plugin~TektonHub%",
"labelPlural": "%pipelines-plugin~TektonHubs%",
"abbr": "TH"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "operator.tekton.dev",
"version": "v1alpha1",
"kind": "TektonResult"
},
"color": "#38812f",
"label": "%pipelines-plugin~TektonResult%",
"labelPlural": "%pipelines-plugin~TektonResults%",
"abbr": "TR"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1alpha1",
"kind": "Condition"
},
"flag": "OPENSHIFT_PIPELINE_CONDITION"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "pipelinesascode.tekton.dev",
"version": "v1alpha1",
"kind": "Repository"
},
"flag": "OPENSHIFT_PIPELINE_AS_CODE"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Pipeline"
},
"flag": "OPENSHIFT_PIPELINE_V1BETA1"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Pipeline"
},
"flag": "OPENSHIFT_PIPELINE"
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Pipeline"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Pipeline"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1alpha1",
"kind": "PipelineResource"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineResourceDetailsPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1alpha1",
"kind": "Condition"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineConditionDetailsPage))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_CONDITION"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_CONDITIONS_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "PipelineRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineRunDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUN_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "PipelineRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineRunDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUN_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "TaskRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskRunDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKRUN_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "TaskRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskRunDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKRUN_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "pipelinesascode.tekton.dev",
"version": "v1alpha1",
"kind": "Repository"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.RepositoryDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_REPOSITORY_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "ClusterTask"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.ClusterTaskDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_CLUSTERTASK_DETAILS",
"PIPELINES_OPERATOR_VERSION_1_17_OR_NEWER"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Task"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASK_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Task"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskDetailsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASK_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "EventListener"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.EventListenerPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_EVENTLISTENERS_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "TriggerTemplate"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.TriggerTemplatePage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TRIGGERTEMPLATE_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "TriggerBinding"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.TriggerBindingPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TRIGGERBINDING_DETAILS"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "triggers.tekton.dev",
"version": "v1beta1",
"kind": "ClusterTriggerBinding"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.ClusterTriggerBindingPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_CLUSTERTRIGGERSBINDING_DETAILS"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Pipeline"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelinesResourceList))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINES_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "PipelineRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineRunsK8sResourceList))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUNS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "PipelineRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineRunsK8sResourceList))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUNS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Pipeline"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelinesResourceList))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINES_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "TaskRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskRunsListPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKRUNS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "TaskRun"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskRunsListPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKRUNS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1alpha1",
"kind": "Condition"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.ConditionListPage))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_CONDITION"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_CONDITIONS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Task"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskListPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Task"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TaskListPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKS_LIST"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "ClusterTask"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.ClusterTaskListPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_CLUSTERTASKS_LIST",
"PIPELINES_OPERATOR_VERSION_1_17_OR_NEWER"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "ClusterTask"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.ClusterTaskListPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_CLUSTERTASKS_LIST",
"PIPELINES_OPERATOR_VERSION_1_17_OR_NEWER"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/pipelines",
"/tasks",
"/triggers",
"/dev-pipelines"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelineComponent' */).then((m) => m.NamespaceRedirect))
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/pipelines/all-namespaces",
"/pipelines/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelinesListsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_NAV_OPTION"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/tasks/all-namespaces",
"/tasks/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/tasks-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/tasksComponent' */).then((m) => m.TasksListsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKS_NAV_OPTION"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/triggers/all-namespaces",
"/triggers/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/triggers-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/triggersComponent' */).then((m) => m.TriggersPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TRIGGERS_NAV_OPTION"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/pac/all-namespaces",
"/pac/ns/:ns"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pac/index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pacComponent' */).then((m) => m.PacPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"perspective": "dev",
"path": [
"/k8s/all-namespaces/tekton.dev~v1beta1~Pipeline",
"/k8s/ns/:ns/tekton.dev~v1beta1~Pipeline"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelinesPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINES_LIST"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": "/k8s/ns/:ns/tekton.dev~v1beta1~Pipeline/~new/builder",
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineBuilderPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/k8s/ns/:ns/tekton.dev~v1~PipelineRun/:plrName/logs/:taskName",
"/k8s/ns/:ns/tekton.dev~v1beta1~PipelineRun/:plrName/logs/:taskName"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.LogURLRedirect))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": "/k8s/ns/:ns/tekton.dev~v1~Pipeline/~new/builder",
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineBuilderPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": "/k8s/ns/:ns/tekton.dev~v1beta1~Pipeline/:pipelineName/builder",
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineBuilderEditPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": "/k8s/ns/:ns/tekton.dev~v1~Pipeline/:pipelineName/builder",
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineBuilderEditPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": "/k8s/ns/:ns/pipelinesascode.tekton.dev~v1alpha1~Repository/~new/form",
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.RepositoryFormPage))
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"perspective": "dev",
"path": [
"/k8s/all-namespaces/tekton.dev~v1alpha1~PipelineRun",
"/k8s/ns/:ns/tekton.dev~v1alpha1~PipelineRun"
],
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineRunsPage))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUNS_LIST"
]
}
},
{
"type": "console.topology/decorator/provider",
"properties": {
"id": "pipeline-run-decorator",
"priority": 100,
"quadrant": "lowerLeft",
"decorator": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/topology' /* webpackChunkName: 'pipelines-plugin/code-refs/topology' */).then((m) => m.getPipelineRunDecorator))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
]
}
},
{
"type": "console.topology/data/factory",
"properties": {
"id": "pipeline-topology-model-factory",
"priority": 800,
"resources": {
"pipelines": {
"model": {
"kind": "Pipeline",
"group": "tekton.dev",
"version": "v1beta1"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
},
"pipelineRuns": {
"model": {
"kind": "PipelineRun",
"group": "tekton.dev",
"version": "v1beta1"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
}
},
"getDataModelReconciler": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/topology' /* webpackChunkName: 'pipelines-plugin/code-refs/topology' */).then((m) => m.getPipelinesDataModelReconciler))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
]
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.useIsTektonV1VersionPresent))
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.useIsPipelineOperatorVersion_1_16_or_newer))
}
},
{
"type": "dev-console.add/action-group",
"properties": {
"id": "pipelines",
"name": "%pipelines-plugin~Pipelines%",
"insertBefore": "samples",
"insertAfter": "local-machine"
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"OPENSHIFT_PIPELINE_V1BETA1"
],
"disallowed": [
"FLAG_TEKTON_V1_ENABLED",
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
},
"properties": {
"id": "pipeline",
"groupId": "pipelines",
"href": "/k8s/ns/:namespace/tekton.dev~v1beta1~Pipeline/~new/builder",
"label": "%pipelines-plugin~Pipelines%",
"description": "%pipelines-plugin~Create a Tekton Pipeline to automate delivery of your application%",
"icon": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/utils/icons.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/icons' */).then((m) => m.pipelineIconSVG)),
"accessReview": [
{
"group": "tekton.dev",
"resource": "pipelines",
"verb": "create"
}
]
}
},
{
"type": "dev-console.add/action",
"flags": {
"required": [
"OPENSHIFT_PIPELINE",
"FLAG_TEKTON_V1_ENABLED"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
},
"properties": {
"id": "pipeline",
"groupId": "pipelines",
"href": "/k8s/ns/:namespace/tekton.dev~v1~Pipeline/~new/builder",
"label": "%pipelines-plugin~Pipelines%",
"description": "%pipelines-plugin~Create a Tekton Pipeline to automate delivery of your application%",
"icon": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/utils/icons.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/icons' */).then((m) => m.pipelineIconSVG)),
"accessReview": [
{
"group": "tekton.dev",
"resource": "pipelines",
"verb": "create"
}
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelines",
"perspective": "dev",
"section": "resources",
"insertAfter": "builds",
"name": "%pipelines-plugin~Pipelines%",
"href": "/dev-pipelines",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-pipelines",
"data-test-id": "pipeline-header"
}
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE",
"FLAG_TEKTON_V1_ENABLED"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelines",
"perspective": "dev",
"section": "resources",
"insertAfter": "builds",
"name": "%pipelines-plugin~Pipelines%",
"href": "/dev-pipelines",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-pipelines",
"data-test-id": "pipeline-header"
}
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_V1BETA1"
],
"disallowed": [
"FLAG_TEKTON_V1_ENABLED",
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelines",
"perspective": "admin",
"section": "pipelines",
"name": "%pipelines-plugin~Pipelines%",
"href": "/pipelines",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-pipelines"
}
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE",
"FLAG_TEKTON_V1_ENABLED"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelinetasks",
"perspective": "admin",
"section": "pipelines",
"name": "%pipelines-plugin~Tasks%",
"href": "/tasks",
"namespaced": true
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE",
"FLAG_TEKTON_V1_ENABLED"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TASKS_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelinetriggers",
"perspective": "admin",
"section": "pipelines",
"name": "%pipelines-plugin~Triggers%",
"href": "/triggers",
"namespaced": true
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE",
"FLAG_TEKTON_V1_ENABLED"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_TRIGGERS_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelines",
"perspective": "admin",
"section": "pipelines",
"name": "%pipelines-plugin~Pipelines%",
"href": "/pipelines",
"namespaced": true,
"dataAttributes": {
"data-quickstart-id": "qs-nav-pipelines"
}
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_V1BETA1"
],
"disallowed": [
"FLAG_TEKTON_V1_ENABLED",
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelinetasks",
"perspective": "admin",
"section": "pipelines",
"name": "%pipelines-plugin~Tasks%",
"href": "/tasks",
"namespaced": true
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_V1BETA1"
],
"disallowed": [
"FLAG_TEKTON_V1_ENABLED",
"HIDE_STATIC_PIPELINE_PLUGIN_TASKS_NAV_OPTION"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "pipelinetriggers",
"perspective": "admin",
"section": "pipelines",
"name": "%pipelines-plugin~Triggers%",
"href": "/triggers",
"namespaced": true
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_V1BETA1"
],
"disallowed": [
"FLAG_TEKTON_V1_ENABLED",
"HIDE_STATIC_PIPELINE_PLUGIN_TRIGGERS_NAV_OPTION"
]
}
},
{
"type": "console.yaml-template",
"properties": {
"name": "default",
"model": {
"group": "tekton.dev",
"kind": "Pipeline",
"version": "v1beta1"
},
"template": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/templates/pipelines.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/yamlTemplates' */).then((m) => m.newPipelineTemplate))
}
},
{
"type": "console.yaml-template",
"properties": {
"name": "default",
"model": {
"group": "tekton.dev",
"kind": "Task",
"version": "v1beta1"
},
"template": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/templates/pipelines.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/yamlTemplates' */).then((m) => m.newTaskTemplate))
}
},
{
"type": "console.yaml-template",
"properties": {
"name": "default",
"model": {
"group": "tekton.dev",
"kind": "TaskRunModel",
"version": "v1beta1"
},
"template": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/templates/pipelines.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/yamlTemplates' */).then((m) => m.newTaskRunTemplate))
}
},
{
"type": "console.yaml-template",
"properties": {
"name": "default",
"model": {
"group": "tekton.dev",
"kind": "PipelineResource",
"version": "v1alpha1"
},
"template": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/templates/pipelines.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/yamlTemplates' */).then((m) => m.newPipelineResourceTemplate))
}
},
{
"type": "console.yaml-template",
"properties": {
"name": "default",
"model": {
"group": "tekton.dev",
"kind": "ClusterTask",
"version": "v1beta1"
},
"template": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/templates/pipelines.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/yamlTemplates' */).then((m) => m.newClusterTaskTemplate))
},
"flags": {
"disallowed": [
"PIPELINES_OPERATOR_VERSION_1_17_OR_NEWER"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "Red Hat",
"title": "%pipelines-plugin~Red Hat%",
"catalogDescription": "%pipelines-plugin~Browse for openshift pipeline tasks available in the cluster.%"
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER",
"PIPELINES_OPERATOR_VERSION_1_17_OR_NEWER"
]
}
},
{
"type": "console.catalog/item-type",
"properties": {
"type": "Community",
"title": "%pipelines-plugin~Community%",
"catalogDescription": "%pipelines-plugin~Browse tekton hub tasks.%"
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "pipelines-task-catalog",
"type": "Red Hat",
"title": "%pipelines-plugin~Tasks%",
"provider": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/catalog' /* webpackChunkName: 'pipelines-plugin/code-refs/catalog' */).then((m) => m.TektonTaskProvider))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "pipelines-task-catalog",
"type": "Community",
"title": "%pipelines-plugin~Tasks%",
"provider": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/catalog' /* webpackChunkName: 'pipelines-plugin/code-refs/catalog' */).then((m) => m.TektonHubTaskProvider))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.catalog/item-provider",
"properties": {
"catalogId": "pipelines-task-catalog",
"type": "Community",
"title": "%pipelines-plugin~Tasks%",
"provider": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/catalog' /* webpackChunkName: 'pipelines-plugin/code-refs/catalog' */).then((m) => m.ArtifactHubTaskProvider))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_BUILDER"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-pipelines-overview",
"tab": "topology-side-bar-tab-resource",
"insertBefore": "topology-tab-section-network-overview",
"provider": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/topology' /* webpackChunkName: 'pipelines-plugin/code-refs/topology' */).then((m) => m.usePipelinesSideBarTabSection))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
]
}
},
{
"type": "console.page/route",
"properties": {
"path": [
"/dev-pipelines/ns/:ns",
"/dev-pipelines/all-namespaces"
],
"exact": false,
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineTabbedPage))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_NAV_OPTION"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "pipelinesascode.tekton.dev",
"version": "v1alpha1",
"kind": "Repository"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.RepositoriesList))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE_AS_CODE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_REPOSITORIES_LIST"
]
}
},
{
"type": "console.navigation/section",
"properties": {
"perspective": "admin",
"id": "pipelines",
"name": "%pipelines-plugin~Pipelines%",
"insertAfter": "builds",
"insertBefore": "servicecatalog",
"dataAttributes": {
"data-quickstart-id": "qs-nav-pipelines",
"data-test": "nav-pipelines"
}
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "Pipeline"
},
"page": {
"name": "%pipelines-plugin~Metrics%",
"href": "metrics"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineMetrics))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_DETAIL_METRICS_TAB"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "Pipeline"
},
"page": {
"name": "%pipelines-plugin~Metrics%",
"href": "metrics"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.PipelineMetrics))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINE_DETAIL_METRICS_TAB"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "PipelineRun"
},
"page": {
"name": "%pipelines-plugin~TaskRuns%",
"href": "task-runs"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.TaskRuns))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUN_DETAIL_TASKRUNS_TAB"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "PipelineRun"
},
"page": {
"name": "%pipelines-plugin~TaskRuns%",
"href": "task-runs"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/pipelines-index.ts' /* webpackChunkName: 'pipelines-plugin/code-refs/pipelinesComponent' */).then((m) => m.TaskRuns))
},
"flags": {
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUN_DETAIL_TASKRUNS_TAB"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"id": "Output",
"name": "Output",
"model": {
"group": "tekton.dev",
"version": "v1",
"kind": "PipelineRun"
},
"page": {
"name": "%pipelines-plugin~Output%",
"href": "output"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/shared/results' /* webpackChunkName: 'pipelines-plugin/code-refs/resultsComponent' */).then((m) => m.OutputTab))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUN_DETAIL_OUTPUT_TAB"
]
}
},
{
"type": "console.tab/horizontalNav",
"properties": {
"id": "Output",
"name": "Output",
"model": {
"group": "tekton.dev",
"version": "v1beta1",
"kind": "PipelineRun"
},
"page": {
"name": "%pipelines-plugin~Output%",
"href": "output"
},
"component": applyCodeRefSymbol(() => import('@console/pipelines-plugin/src/components/shared/results' /* webpackChunkName: 'pipelines-plugin/code-refs/resultsComponent' */).then((m) => m.OutputTab))
},
"flags": {
"required": [
"OPENSHIFT_PIPELINE"
],
"disallowed": [
"HIDE_STATIC_PIPELINE_PLUGIN_PIPELINERUN_DETAIL_OUTPUT_TAB"
]
}
}
],
],
});
activePlugins.push({
name: '@console/service-binding-plugin',
extensions: [
...require('@console/service-binding-plugin/src/plugin.ts').default,
...[
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "binding.operators.coreos.com"
}
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "binding.operators.coreos.com",
"version": "v1alpha1",
"kind": "ServiceBinding"
},
"label": "%service-binding-plugin~ServiceBinding%",
"labelPlural": "%service-binding-plugin~ServiceBindings%",
"abbr": "SB"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "binding.operators.coreos.com",
"version": "v1alpha1",
"kind": "ServiceBinding"
},
"flag": "ALLOW_SERVICE_BINDING"
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "binding.operators.coreos.com",
"version": "v1alpha1",
"kind": "ServiceBinding"
},
"component": applyCodeRefSymbol(() => import('@console/service-binding-plugin/src/pages.ts' /* webpackChunkName: 'service-binding-plugin/code-refs/pages' */).then((m) => m.ServiceBindingListPage))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "binding.operators.coreos.com",
"version": "v1alpha1",
"kind": "ServiceBinding"
},
"component": applyCodeRefSymbol(() => import('@console/service-binding-plugin/src/pages.ts' /* webpackChunkName: 'service-binding-plugin/code-refs/pages' */).then((m) => m.ServiceBindingDetailsPage))
},
"flags": {
"required": [
"ALLOW_SERVICE_BINDING"
]
}
}
],
],
});
activePlugins.push({
name: '@console/shipwright-plugin',
extensions: [
...require('@console/shipwright-plugin/src/plugin.ts').default,
...[
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "shipwright.io"
},
"color": "#7c8fa4"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "Build"
},
"color": "#7c8fa4",
"label": "%shipwright-plugin~Build%",
"labelPlural": "%shipwright-plugin~Builds%",
"abbr": "B"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "Build"
},
"color": "#7c8fa4",
"label": "%shipwright-plugin~Build%",
"labelPlural": "%shipwright-plugin~Builds%",
"abbr": "B"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildRun"
},
"color": "#7c8fa4",
"label": "%shipwright-plugin~BuildRun%",
"labelPlural": "%shipwright-plugin~BuildRuns%",
"abbr": "BR"
}
},
{
"type": "console.model-metadata",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildRun"
},
"color": "#7c8fa4",
"label": "%shipwright-plugin~BuildRun%",
"labelPlural": "%shipwright-plugin~BuildRuns%",
"abbr": "BR"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "Build"
},
"flag": "SHIPWRIGHT_BUILD_V1ALPHA1"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "Build"
},
"flag": "SHIPWRIGHT_BUILD"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildRun"
},
"flag": "SHIPWRIGHT_BUILDRUN_V1ALPHA1"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildRun"
},
"flag": "SHIPWRIGHT_BUILDRUN"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildStrategy"
},
"flag": "SHIPWRIGHT_BUILDSTRATEGY_V1ALPHA1"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildStrategy"
},
"flag": "SHIPWRIGHT_BUILDSTRATEGY"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "ClusterBuildStrategy"
},
"flag": "SHIPWRIGHT_CLUSTERBUILDSTRATEGY_V1ALPHA1"
}
},
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "ClusterBuildStrategy"
},
"flag": "SHIPWRIGHT_CLUSTERBUILDSTRATEGY"
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "shipwright-tablistpage",
"section": "builds",
"perspective": "admin",
"prefixNamespaced": true,
"href": "/shipwright.io",
"name": "%shipwright-plugin~Shipwright%"
},
"flags": {
"required": [
"SHIPWRIGHT_BUILD"
]
}
},
{
"type": "console.navigation/href",
"properties": {
"id": "shipwright-tablistpage-2",
"section": "builds",
"perspective": "admin",
"prefixNamespaced": true,
"href": "/shipwright.io",
"name": "%shipwright-plugin~Shipwright%"
},
"flags": {
"required": [
"SHIPWRIGHT_BUILD_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILD"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": false,
"path": [
"/k8s/all-namespaces/shipwright.io",
"/k8s/ns/:ns/shipwright.io"
],
"perspective": "admin",
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.ShipwrightTabListPage))
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "Build"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildListPage))
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "Build"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildListPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "Build"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildDetailsPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "Build"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildDetailsPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildStrategy"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildStrategyDetailsPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildStrategy"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildStrategyDetailsPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "ClusterBuildStrategy"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.ClusterBuildStrategyDetailsPage))
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "ClusterBuildStrategy"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.ClusterBuildStrategyDetailsPage))
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildRun"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildRunListPage))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.page/resource/list",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildRun"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildRunListPage))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildRun"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildRunDetailsPage))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.page/resource/details",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildRun"
},
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildRunDetailsPage))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "Build"
},
"provider": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/actions.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/actions' */).then((m) => m.useBuildActions))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILD_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "Build"
},
"provider": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/actions.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/actions' */).then((m) => m.useBuildActions))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1alpha1",
"kind": "BuildRun"
},
"provider": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/actions.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/actions' */).then((m) => m.useBuildRunActions))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.action/resource-provider",
"properties": {
"model": {
"group": "shipwright.io",
"version": "v1beta1",
"kind": "BuildRun"
},
"provider": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/actions.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/actions' */).then((m) => m.useBuildRunActions))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.topology/decorator/provider",
"properties": {
"id": "shipwright-buildruns-decorator",
"priority": 100,
"quadrant": "lowerLeft",
"decorator": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/topology.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/topology' */).then((m) => m.getShipWrightBuildDecorator))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.topology/decorator/provider",
"properties": {
"id": "shipwright-buildruns-decorator",
"priority": 100,
"quadrant": "lowerLeft",
"decorator": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/topology.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/topology' */).then((m) => m.getShipWrightBuildDecorator))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-shipwright-overview",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-pods-overview",
"provider": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/topology.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/topology' */).then((m) => m.useBuildSideBarTabSection))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-shipwright-overview",
"tab": "topology-side-bar-tab-resource",
"insertAfter": "topology-tab-section-pods-overview",
"provider": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/topology.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/topology' */).then((m) => m.useBuildSideBarTabSection))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.topology/data/factory",
"properties": {
"id": "shipwright-topology-model-factory",
"priority": 800,
"resources": {
"builds": {
"model": {
"kind": "Build",
"group": "shipwright.io",
"version": "v1alpha1"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
},
"buildRuns": {
"model": {
"kind": "BuildRun",
"group": "shipwright.io",
"version": "v1alpha1"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
}
},
"getDataModelReconciler": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/topology.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/topology' */).then((m) => m.getShipwrightDataModelReconcilor))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN_V1ALPHA1"
],
"disallowed": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.topology/data/factory",
"properties": {
"id": "shipwright-topology-model-factory",
"priority": 800,
"resources": {
"builds": {
"model": {
"kind": "Build",
"group": "shipwright.io",
"version": "v1beta1"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
},
"buildRuns": {
"model": {
"kind": "BuildRun",
"group": "shipwright.io",
"version": "v1beta1"
},
"opts": {
"isList": true,
"optional": true,
"namespaced": true
}
}
},
"getDataModelReconciler": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/topology.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/topology' */).then((m) => m.getShipwrightDataModelReconcilor))
},
"flags": {
"required": [
"SHIPWRIGHT_BUILDRUN"
]
}
},
{
"type": "console.page/route",
"properties": {
"exact": true,
"path": [
"/k8s/ns/:ns/shipwright.io~v1beta1~Build/~new/form",
"/k8s/ns/:ns/shipwright.io~v1beta1~Build/:name/form"
],
"component": applyCodeRefSymbol(() => import('@console/shipwright-plugin/src/pages.ts' /* webpackChunkName: 'shipwright-plugin/code-refs/pages' */).then((m) => m.BuildFormPage))
}
}
],
],
});
activePlugins.push({
name: '@console/topology',
extensions: [
...require('@console/topology/src/plugin.tsx').default,
...[
{
"type": "console.flag/model",
"properties": {
"model": {
"group": "primer.gitops.io",
"version": "v1alpha1",
"kind": "Export"
},
"flag": "ALLOW_EXPORT_APP"
}
},
{
"type": "console.redux-reducer",
"properties": {
"scope": "devconsole",
"reducer": applyCodeRefSymbol(() => import('@console/topology/src/utils/reducer.ts' /* webpackChunkName: 'topology/code-refs/reduxReducer' */).then((m) => m.default))
}
},
{
"type": "console.topology/details/tab",
"properties": {
"id": "topology-side-bar-tab-details",
"label": "%topology~Details%"
}
},
{
"type": "console.topology/details/tab",
"properties": {
"id": "topology-side-bar-tab-resource",
"label": "%topology~Resources%"
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-deployment-config",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useDeploymentConfigSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-deployment",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useDeploymentSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-daemon-set",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useDaemonSetSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-stateful-set",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useStatefulSetSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-jobs",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useJobSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-cron-jobs",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useCronJobSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-pod",
"tab": "topology-side-bar-tab-details",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.usePodSideBarDetails))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-pods-overview",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.usePodsSideBarTabSection))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-vpa-overview",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useVpaSideBarTabSection))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-jobs-overview",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useJobsSideBarTabSection))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-builds-overview",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useBuildsSideBarTabSection))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-network-overview",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useNetworkingSideBarTabSection))
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 0,
"link": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.getWorkloadResourceLink))
}
},
{
"type": "console.topology/adapter/pod",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.podsAdapterForWorkloads))
}
},
{
"type": "console.topology/adapter/pod",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.podsAdapterForCronJobWorkload))
}
},
{
"type": "console.topology/adapter/build",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.buildsAdapterForWorkloads))
}
},
{
"type": "console.topology/adapter/network",
"properties": {
"adapt": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.networkAdapterForWorkloads))
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/actions/provider.ts' /* webpackChunkName: 'topology/code-refs/actions' */).then((m) => m.useTopologyWorkloadActionProvider))
}
},
{
"type": "console.topology/details/resource-alert",
"properties": {
"id": "health-check-alert",
"contentProvider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useHealthChecksAlert))
}
},
{
"type": "console.topology/details/resource-alert",
"properties": {
"id": "resource-quota-alert",
"contentProvider": applyCodeRefSymbol(() => import('@console/topology/src/components/workload' /* webpackChunkName: 'topology/code-refs/workload' */).then((m) => m.useResourceQuotaAlert))
}
},
{
"type": "console.context-provider",
"properties": {
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/export-app/export-app-context.ts' /* webpackChunkName: 'topology/code-refs/exportAppContext' */).then((m) => m.ExportAppContextProvider)),
"useValueHook": applyCodeRefSymbol(() => import('@console/topology/src/components/export-app/export-app-context.ts' /* webpackChunkName: 'topology/code-refs/exportAppContext' */).then((m) => m.useExportAppFormToast))
},
"flags": {
"required": [
"ALLOW_EXPORT_APP"
]
}
},
{
"type": "console.user-preference/item",
"properties": {
"id": "topology.preferredView",
"label": "%topology~Topology%",
"groupId": "general",
"description": "%topology~If a topology view is not selected, the console defaults to the last viewed.%",
"field": {
"type": "dropdown",
"userSettingsKey": "topology.preferredView",
"defaultValue": "latest",
"options": [
{
"value": "latest",
"label": "%topology~Last viewed%"
},
{
"value": "graph",
"label": "%topology~Graph%"
},
{
"value": "list",
"label": "%topology~List%"
}
]
},
"insertBefore": "console.preferredCreateEditMethod",
"insertAfter": "console.preferredNamespace"
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-application",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/application-panel' /* webpackChunkName: 'topology/code-refs/applicationSidebar' */).then((m) => m.useApplicationPanelResourceTabSection))
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 100,
"link": applyCodeRefSymbol(() => import('@console/topology/src/components/application-panel' /* webpackChunkName: 'topology/code-refs/applicationSidebar' */).then((m) => m.getApplicationPanelResourceLink))
}
},
{
"type": "console.topology/details/resource-link",
"properties": {
"priority": 10,
"link": applyCodeRefSymbol(() => import('@console/topology/src/components/visual-connector' /* webpackChunkName: 'topology/code-refs/vcSidebar' */).then((m) => m.getEdgeResourceLink))
}
},
{
"type": "console.topology/details/tab-section",
"properties": {
"id": "topology-tab-section-visual-connector",
"tab": "topology-side-bar-tab-resource",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/components/visual-connector' /* webpackChunkName: 'topology/code-refs/vcSidebar' */).then((m) => m.useVisualConnectorResourceTabSection))
}
},
{
"type": "console.action/provider",
"properties": {
"contextId": "topology-actions",
"provider": applyCodeRefSymbol(() => import('@console/topology/src/actions/provider.ts' /* webpackChunkName: 'topology/code-refs/actions' */).then((m) => m.useTopologyVisualConnectorActionProvider))
}
}
],
],
});
activePlugins.push({
name: '@console/vsphere-plugin',
extensions: [
...require('@console/vsphere-plugin/src/plugin.ts').default,
...[
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/vsphere-plugin/src/flag.ts' /* webpackChunkName: 'vsphere-plugin/code-refs/flag' */).then((m) => m.useVSphereFlagHandler))
}
},
{
"type": "console.dashboards/overview/health/prometheus",
"flags": {
"required": [
"VSPHERECONNECTION"
]
},
"properties": {
"title": "%vsphere-plugin~vSphere connection%",
"queries": [
"vsphere_sync_errors"
],
"healthHandler": applyCodeRefSymbol(() => import('@console/vsphere-plugin/src/components/ClusterOverview/VSphereStatus.tsx' /* webpackChunkName: 'vsphere-plugin/code-refs/vsphereStatus' */).then((m) => m.healthHandler)),
"popupComponent": applyCodeRefSymbol(() => import('@console/vsphere-plugin/src/components/ClusterOverview/VSphereStatus.tsx' /* webpackChunkName: 'vsphere-plugin/code-refs/vsphereStatus' */).then((m) => m.default)),
"popupClassname": "plugin-vsphere-status-popup",
"popupTitle": "%vsphere-plugin~vSphere configurations%",
"popupKeepOnOutsideClick": true,
"additionalResource": {
"kind": "ConfigMap",
"name": "cloud-provider-config",
"namespace": "openshift-config",
"isList": false,
"namespaced": true
}
}
}
],
],
});
activePlugins.push({
name: '@console/webterminal-plugin',
extensions: [
...require('@console/webterminal-plugin/src/plugin.ts').default,
...[
{
"type": "console.redux-reducer",
"properties": {
"scope": "console",
"reducer": applyCodeRefSymbol(() => import('@console/webterminal-plugin/src/redux/reducer.ts' /* webpackChunkName: 'webterminal-plugin/code-refs/reduxReducer' */).then((m) => m.default))
}
},
{
"type": "console.flag/hookProvider",
"properties": {
"handler": applyCodeRefSymbol(() => import('@console/webterminal-plugin/src/actions/providers' /* webpackChunkName: 'webterminal-plugin/code-refs/actions' */).then((m) => m.useWebTerminalProvider))
}
},
{
"type": "console.cluster-configuration/group",
"properties": {
"id": "webTerminal",
"label": "%webterminal-plugin~Web Terminal%",
"insertAfter": "developer"
},
"flags": {
"required": [
"WEB_TERMINAL"
]
}
},
{
"type": "console.cluster-configuration/item",
"properties": {
"id": "webterminal.WebTerminalConfiguration",
"groupId": "webTerminal",
"label": "%webterminal-plugin~Web Terminal%",
"description": "%webterminal-plugin~Configure default timeout and image of web terminal operator.%",
"field": {
"type": "custom",
"component": applyCodeRefSymbol(() => import('@console/webterminal-plugin/src/components/cloud-shell/WebTerminalConfiguration.tsx' /* webpackChunkName: 'webterminal-plugin/code-refs/webTerminalConfiguration' */).then((m) => m.default))
},
"readAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "get",
"name": "cluster"
},
{
"group": "workspace.devfile.io/v1alpha2",
"resource": "DevWorkspaceTemplate",
"verb": "get",
"name": "web-terminal-exec"
},
{
"group": "workspace.devfile.io/v1alpha2",
"resource": "DevWorkspaceTemplate",
"verb": "get",
"name": "web-terminal-tooling"
}
],
"writeAccessReview": [
{
"group": "operator.openshift.io/v1",
"resource": "consoles",
"verb": "patch",
"name": "cluster"
},
{
"group": "workspace.devfile.io/v1alpha2",
"resource": "DevWorkspaceTemplate",
"verb": "patch",
"name": "web-terminal-exec"
},
{
"group": "workspace.devfile.io/v1alpha2",
"resource": "DevWorkspaceTemplate",
"verb": "patch",
"name": "web-terminal-tooling"
}
]
}
}
],
],
});
export default activePlugins;
